.table {
  margin-bottom: 1em;
}

.table thead tr {
  background: rgba(220, 205, 178, 0.3);
}

.table thead tr th {
  text-align: left;
  font-weight: 600;
  padding: 0.25em 0.5em;
}

.table tbody tr:nth-child(even) {
  background: rgba(0, 0, 0, 0.025);
}

.table tbody tr td {
  text-align: left;
  padding: 0.5em;
  color: #5b6a72;
}

.table tbody tr td strong {
  font-weight: 600;
}

.table tbody .total {
  border-top: 1px solid #cac8c8;
  background: none !important;
}

.table tbody .total td {
  font-weight: 600;
  font-size: 1.1em;
}

.table tbody .total td:first-child {
  font-size: 1em;
  font-weight: normal;
}

.table--block {
  width: 100%;
}

.table--fixed {
  table-layout: fixed;
}

.table--stats {
  min-width: 510px;
}

.table--stats thead tr th {
  text-align: center;
  border-right: 1px solid #cac8c8;
}

.table--stats thead tr th:last-child {
  border-right: none;
}

.table--stats tbody tr td {
  text-align: center;
  white-space: nowrap;
  border-right: 1px solid #cac8c8;
}

.table--stats tbody tr td:last-child {
  border-right: none;
}

.table--overview {
  width: 100%;
}

.table--overview thead tr th:last-child,
.table--overview tbody tr td:last-child {
  text-align: right;
}

.table--overview tbody strong {
  font-weight: bold;
  color: #000;
}

.table--small {
  min-width: 320px;
  max-width: 400px;
}

@media print {
  .table thead tr {
    border-bottom: 1px solid #ddd;
  }
}