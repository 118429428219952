.loader { 
  position: absolute;
  top: calc(50% - 60px);
  left: calc(50% - 60px);
  height: 120px;
  width: 120px;
  animation: axe 500ms infinite linear;
  fill: #fff;
}

.loader--small {
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  height: 50px;
  width: 50px;
}

.btn .loader {
  position: relative;
  top: auto;
  left: auto;
  margin: -6px 16px -6px 20px;
  float: none;
  display: block;
  height: 32px;
  width: 32px;
  fill: #aaa;
}

@keyframes axe {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}