.accordion {
  margin-bottom: 1px;
  .accordion__header {
    transition: 220ms;
    font-family: inherit;
    font-weight: 600;
    font-size: 18px;
    padding: 10px 48px 10px 20px;
    background: #4B565E;
    color: #fff;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    display: flex;
    justify-content: space-between;
    border: none;
    width: 100%;
    outline: none;
    &:hover {
      background: #5b6872;
    }

    .accordion__points {
      border-right: 1px solid rgba(255, 255, 255, 0.15);
      padding-right: 12px;
    }
    svg {
      position: absolute;
      top: 12px;
      right: 16px;
    }
    .icon-minus-thin,
    .icon-plus-thin {
      font-size: 26px;
      top: 6px;
      right: 12px;
    }
  }
  .accordion__inner {
    display: none;
    padding: 1em;
    border: 1px solid #cac8c8;
    border-top: none;
    overflow: hidden;
    background: #F2EEE7;
  }

  &--open {
    .accordion__inner {
      display: block;
    }
  }
  
  &--new {
    > .accordion__header {
      background: #F1EEE7;
      padding: 8px 48px 8px 18px;
      border: 2px dashed #4B565E;
      color: #222;
      &:hover {
        background: #E4E2DB;
      }
    }
    > .accordion__inner {
      border: 2px dashed #4B565E;
      border-top: none;
    }
  }
}

#new-wargear-item {
  margin-bottom: 10px;
}