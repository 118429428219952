.new-card {
  overflow: hidden;
  color: #fff;
  border: 3px dashed #fff;
  border-radius: 15px;
  margin-top: 28px;
  position: relative;
  cursor: pointer;
}

.card--small .new-card {
  margin-top: 0;
}

.new-card__inner {
  transition: 220ms;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 28px);
  min-height: 592px;
}

.card--small .new-card__inner {
  min-height: 200px;
}

.new-card__inner:before {
  transition: 220ms;
  content: '';
  position: absolute;
  top: 15px;
  right: 15px;
  left: 15px;
  bottom: 15px;
  border: 2px solid rgba(255, 255, 255, 0.2);
}

.new-card:hover .new-card__inner {
  transform: scale(1.1);
}

.new-card:hover .new-card__inner:before {
  background: rgba(255, 255, 255, 0.2);
  border-color: transparent;
}

.card--small .new-card:hover .new-card__inner:before {
  height: 100%;
  top: 0;
}

.new-card span {
  transition: 220ms;
  font-size: 28px;
  display: block;
  margin-bottom: 20px;
  font-weight: 600;
}

.new-card img {
  width: 15%;
  margin-top: -40px;
  margin-bottom: 20px;
}

.new-card .icon-plus {
  transition: 220ms;
  font-size: 48px;
  border: 2px solid #fff;
  padding: 0.22em;
  border-radius: 50%;
  display: block;
}

@media print {  
  .new-card {
    display: none;
  }
}