.file-upload {
  flex: 1 100%;
  border: 1px solid #ccc;
  overflow: hidden;
}

.file-upload input {
  position: absolute;
  left: -999em;
  visibility: hidden;
}

.file-upload .btn {
  padding: 10px 20px;
  margin: -1px 0;
  font-size: 1em;
  float: left;
  width: 50%;
  line-height: 14.5px;
}

.file-upload .btn:first-child {
  width: 100%;
}

.file-upload .btn .loader {
  height: 25px;
}

.file-upload .btn .icon-arrow-right {
  transform: rotate(-90deg);
  padding-left: 2px;
  border-left: 0.075em solid white;
  height: 15px;
  margin-top: 1px;
  margin-left: 8px;
}

.file-upload .btn--secondary {
  border: none;
  padding: 11px 20px;
}

.file-upload .btn--secondary:hover {
  background: none;
  color: #de932f;
}

.file-upload .file-upload__change {
  flex: 0 0 auto;
}

.file-upload .file-upload__name {
  clear: both;
  font-weight: 600;
  padding: 10px;
  display: block;
}

.file-upload .file-upload__name--error {
  color: #ca1b1b;
}

@media screen and (min-width: 600px) {
  .file-upload {
    display: flex;
    align-items: center;
  }

  .file-upload .btn,
  .file-upload .btn:first-child {
    width: auto;
  }

  .file-upload .file-upload__name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1;
    padding: 0 10px;
  }
}

@media screen and (min-width: 960px) {
  .file-upload {
    max-width: calc(100% - 115px);
  }
}
