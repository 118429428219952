.new-deck {
  overflow: hidden;
  color: #fff;
  border: 3px dashed #fff;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
  max-width: 350px;
  margin: 0.5em;
}


@media only screen and (min-width: 600px) {
  .new-deck {
    margin-top: -1em;
  }
}

.new-deck__inner {
  transition: 220ms;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  min-height: 494px;
}

.new-deck__inner:before {
  transition: 220ms;
  content: '';
  position: absolute;
  top: 15px;
  right: 15px;
  left: 15px;
  bottom: 15px;
  border: 2px solid rgba(255, 255, 255, 0.2);
}

.new-deck:hover .new-deck__inner {
  transform: scale(1.1);
}

.new-deck:hover .new-deck__inner:before {
  background: rgba(255, 255, 255, 0.2);
  border-color: transparent;
}

.new-deck span {
  transition: 220ms;
  font-size: 28px;
  display: block;
  margin-bottom: 20px;
  font-weight: 600;
}

.new-deck img {
  width: 25%;
  margin-top: -45px;
  margin-bottom: 20px;
}

.new-deck .icon-plus {
  transition: 220ms;
  font-size: 48px;
  border: 2px solid #fff;
  padding: 0.22em;
  border-radius: 50%;
  display: block;
}