#header-main {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  background: #10161d;
  box-shadow: inset -10px 0 15px 0 rgba(0, 0, 0, 0.8);
}

#header-main.nav-open #main-nav .nav-caller .line:first-child {
  transform: rotate(-45deg) translate3d(-4px, 1px, 0);
  width: 15px;
}

#header-main.nav-open #main-nav .nav-caller .line:last-child {
  transform: rotate(45deg) translate3d(-4px, -1px, 0);
  width: 15px;
}

#header-main.nav-open #main-nav .sliding-nav {
  transform: translateX(0);
}

@media screen and (min-width: 980px) {
  #header-main {
    position: -webkit-sticky;
    position: sticky;
    height: 100vh;
    flex: 0 0 260px;
    max-width: 260px;
    overflow: auto;
  }
}

@media screen and (min-width: 980px) {
  #header-main .container {
    height: 100%;
    display: flex;
    flex-flow: column;
  }
}

#header-main .logo {
  display: none;
}

#header-main .logo-mobile {
  float: left;
  width: 180px;
  padding: 0.5em 1em;
}

@media screen and (min-width: 980px) {
  #header-main .logo {
    display: block;
    width: 170px;
    margin: 1em 1em 2em;
  }
  #header-main .logo-mobile {
    display: none;
  }
}

@media screen and (min-width: 980px) {
  #header-main #main-nav {
    flex: auto;
  }
  #header-main #main-nav .sliding-nav {
    transform: none;
    position: relative;
    max-width: none;
    width: auto;
    top: auto;
    right: auto;
    background: none;
    height: 100%;
  }
}

#header-main #main-nav .nav-caller {
  transition: all 220ms;
  text-decoration: none;
  padding: 12px 15px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  background: none;
  border: none;
}

@media screen and (min-width: 980px) {
  #header-main #main-nav .nav-caller {
    display: none;
  }
}

#header-main #main-nav .nav-caller .line {
  transition: 220ms;
  display: block;
  width: 22px;
  height: 3px;
  background: #fff;
  margin: 5px 0;
}

#header-main #main-nav .sliding-nav {
  transform: translateX(-100%);
  transition: transform 220ms;
  overflow: auto;
  height: calc(100vh - 52px);
  width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  background: #10161d;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

#header-main #main-nav .sliding-nav > ul {
  margin: 0;
  padding: 1em 1em 4em;
  overflow: auto;
}

#header-main #main-nav .sliding-nav > .btn {
  position: absolute;
  bottom: 55px;
  left: 0;
  width: 100%;
}

#header-main #main-nav .sliding-nav li a {
  transition: 220ms;
}

#header-main #main-nav .sliding-nav li a:hover {
  transform: translateX(5px);
}

#header-main #main-nav .sliding-nav ul button,
#header-main #main-nav .sliding-nav ul a {
  color: #fff;
  display: block;
  padding: 0.4em 1em 0.4em 0;
  text-decoration: none;
  font-family: inherit;
  background: none;
  font-weight: normal;
  font-size: 18px;
}

#header-main #main-nav .sliding-nav > ul > li svg {
  margin-right: 5px;
  vertical-align: middle;
}

#header-main #main-nav .sliding-nav > ul > li ul {
  margin-left: 0.5em;
  display: none;
}

#header-main #main-nav .sliding-nav > ul > li li {
  font-size: 1rem;
}

#header-main #main-nav .sliding-nav > ul > li.active > a {
  color: #ffb000;
}

#header-main #main-nav .sliding-nav > ul > li.active ul {
  display: block;
}

#header-main #main-nav .sliding-nav > ul > li li svg {
  color: #425562;
  font-size: 0.9em;
  margin-top: -4px;
}

#header-main #main-nav .sliding-nav > ul > .company > a {
  font-size: 1.2em;
  color: #bf7d26;
  font-weight: 600;
}

#header-main #main-nav .sliding-nav > ul > li > ul > li > a:hover {
  color: #ffb000;
}

#header-main #main-nav .sliding-nav > ul > li li a {
  font-size: 16px;
  padding: 0.25em;
}

#header-main #main-nav .sliding-nav > ul > li li.leader,
#header-main #main-nav .sliding-nav > ul > li li.hero,
#header-main #main-nav .sliding-nav > ul > li li.sergeant {
  font-weight: 600;
}

#header-main #main-nav .sliding-nav > ul > li li.leader svg {
  color: #ffb000;
}

#header-main #main-nav .sliding-nav > ul > li li.sergeant svg {
  color: #cac0cc;
}

#header-main #main-nav .sliding-nav > ul > li li.hero svg {
  color: #9b6a54;
}

@media screen and (min-width: 600px) {
  #header-main #main-nav .sliding-nav {
    max-width: 350px;
  }
}

@media screen and (min-width: 980px) {
  #header-main #main-nav .sliding-nav {
    transform: none;
    position: relative;
    max-width: none;
    width: auto;
    top: auto;
    right: auto;
    background: none;
    height: 100%;
    overflow: visible;
  }

  #header-main #main-nav .sliding-nav > ul {
    padding: 1em;
  }  

  #header-main #main-nav .sliding-nav > .btn {
    position: relative;
    bottom: 0;
  }
}

@media print {
  #header-main {
    display: none;
  }
}