.sort {
  position: relative;
  width: 100%;
  min-width: 60px;
  flex: 1 0 60px;

  &__toggle {
    text-transform: capitalize;
  }

  &__options {
    position: absolute;
    top:100%;
    right: 0;
    z-index: 10;
    width: 200px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }

  &__option {
    display: flex;
    text-transform: capitalize;
    align-items: center;
    padding: 12px 12px 12px 20px;
    color: #c2c5c7;
    font-size: 0.825em;
    background: #3b424c;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    width: 100%;
    transition: color 220ms, background-color 220ms, border-color 220ms;
    font-family: inherit;

    &:hover,
    &--selected {
      background-color: #4d545f;
      color: #fff;
    }

    svg {
      margin-left: auto;
    }
  }
}