.header {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 40px;
}

.header__top__inner {
  transition: 220ms;
  text-align: center;
  background: #2b323a;
  padding: 12px 20px;
  color: #bf7d26;
  border: none;
  display: block;
  width: 100%;
  line-height: 120%;
  font-family: inherit;
}

.header__sort,
.header__buttons {
  display: flex;

  .btn {
    padding: 0.5em;
    font-size: 0.75em;
    display: flex;
    flex-flow: column;
    align-items: center;
    flex: 1 0 60px;
    min-width: 60px;
    margin-left: 1px;

    &--primary {
      flex: 1 0 100px;
      min-width: 100px;
    }

    &--secondary {
      color: #c2c5c7;
      background: #3b424c;
      border-color: transparent;

      &:hover {
        background: #4d545f;
        color: #fff;
      }
    }

    svg {
      transition: 0ms;
      color: currentColor;
      font-size: 2em;
      margin: 0.3em 0 0.2em;
      order: -1;
    }
  }
}

button.header:hover {
  background: #39414a;
}

.header--loading .header__top__inner {
  min-height: 68px;
  position: relative;
}

.header__name {
  font-weight: 600;
  color: #fff;
  display: block;
  font-size: 24px;
  line-height: 24px;
}

.header__right {
  display: flex;
  border-top: 1px solid #4B565E;
  margin-top: 1em;
  padding-top: 1em;
}

.header__value {
  flex: 1 1 50%;
  text-align: center;
}

.header__value strong {
  color: #fff;
  font-size: 24px;
  line-height: 24px;
  display: block;
}

.header__bottom {
  background: #222830;
  padding: 12px 20px;
  color: #bf7d26;
}

.header__bottom .header__left {
  display: flex;
}

.header-counter {
  position: relative;
  padding-right: 75px;
  margin-left: 10px;
}

.header-counter__dec, .header-counter__inc {
  transition: 220ms;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 2px;
  right: 0;
  z-index: 2;
  height: 30px;
  width: 32px;
  background: #4B565E;
  border: 1px solid #5d656b;
  cursor: pointer;
  padding: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  color: #fff;
}

.header-counter__dec:hover,
.header-counter__inc:hover {
  background: #5b6872;
}

.header-counter__dec:disabled,
.header-counter__inc:disabled {
  background: #222830;
  border-color: #4B565E;
  color: #4B565E;
  cursor: default;
}

.header-counter__dec:disabled:hover,
.header-counter__inc:disabled:hover {
  background: #282B32;
}

.header-counter__dec svg, .header-counter__inc svg {
  font-size: 0.75em;
  margin: 0 auto;
}

.header-counter__inc {
  border-radius: 0 50% 50% 0;
}

.header-counter__dec {
  border-radius: 50% 0 0 50%;
  right: 32px;
}

.header-counter__val {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  vertical-align: text-bottom;
  line-height: 28px;
  display: inline-block;
  color: #fff;
}

@media screen and (min-width: 768px) {
  .header__top {
    display: flex;
  }

  .header__name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .header__top__inner {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    text-align: left;
  }

  .header__top .header__buttons {
    flex: 1 1 240px;
  }

  .header__top .header__sort {
    flex: 1 1 75px;
  }

  .header__right {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
  }

  .header__value {
    flex: 1 1 auto;
    margin-left: 15px;
  }

  .header__bottom {
    flex: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header__bottom .header__left .header__value {
    margin-left: 0;
    margin-right: 20px;
  }

  .header__bottom .header__right .header__value {
    margin-left: 20px;
  }
}

@media screen and (min-width: 1440px) {
  .header__bottom .header__left .header__value {
    margin-right: 30px;
  }

  .header__bottom .header__right .header__value {
    margin-left: 30px;
  }

  .header__top .header__buttons {
    flex: 1 0 auto;
  }

  .header__top .header__buttons .btn--primary {
    flex: 1 0 150px;
    min-width: 150px;
  }
}
