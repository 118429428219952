input[type="radio"] {
  width: auto;
}

.radio-list .radio {
  display: block;
}

@media screen and (min-width: 980px) { 
  .radio-list {
    display: flex;
    flex-flow: row wrap;
    flex: 1;
  } 
  .radio-list .radio {
    flex: 0 1 auto;
    margin-right: 12px;
  }
  .radio-list .radio:last-child {
    margin-right: 0;
  }
}

.radio {
  padding-left: 34px;
  line-height: 26px;
  overflow: hidden;
  margin: 3px 0;
  font-weight: normal;
}

.radio input {
  position: absolute;
  left: -999em;
}

.radio__input {
  float: left;
  margin-left: -34px;
  background: #fff;
  border: 1px solid #cac8c8;
  border-radius: 50%;
}

.radio:hover .radio__input {
  border-color: #aaa;
}

.radio__input:before {
  content: '';
  transition: 220ms;
  transform: scale(0.8);
  display: block;
  height: 16px;
  width: 16px;
  margin: 5px;
  background: #de932f;
  border-radius: 50%;
  opacity: 0;
}

.radio input:checked ~ .radio__input:before {
  transform: scale(1);
  opacity: 1;
}