.number-control {
  position: relative;
  width: 105px;
  flex: 0 0 105px;
  margin: 6px 0;
}

.number-control__dec, .number-control__inc {
  transition: 220ms;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  padding: 0;
  background: #f1f1f1;
  border: 1px solid #cac8c8;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #4D4D4D;
  outline: none;
  margin: 0 auto;
}

.number-control__dec:focus, .number-control__inc:focus {
  border-color: #de932f;
}

.number-control__dec:hover, .number-control__inc:hover {
  background: #e4e2e2;
}

.number-control__dec:disabled, .number-control__inc:disabled {
  background: transparent;
  color: #cac8c8;
}

.number-control__dec svg, .number-control__inc svg {
  font-size: 0.75em;
  margin: 0 auto;
}

.number-control__inc {
  left: auto;
  right: 0;
}

.number-control__val {
  border-radius: 50%;
  text-align: center;
  margin: 2px 0;
  padding: 0 28px;
  border: none;
  font-size: 22px;
  font-weight: bold;
  height: 24px;
  line-height: 24px;
  outline: none;
  background: none;
}

.number-control--read-only .number-control__val {
  padding: 0;
}