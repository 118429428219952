.wound-chart {
  display: flex;
  flex-flow: row wrap;
}

.wound-chart__x-label {
  display: none;
  text-align: center;
  flex: 1 0 100%;
  margin: 15px 0 10px;

  @media (min-width: 768px) {
    display: block;
  }
}

.wound-chart__y-label {
  display: none;
  text-align: center;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  margin: 0 10px 0 15px;

  @media (min-width: 768px) {
    display: block;
  }
}

.wound-chart__table {
  position: relative;
  z-index: 1;
  overflow: hidden;
  table-layout: fixed;
  margin: 10px;
  border-radius: 0 0 10px 10px;

  @media (min-width: 768px) {
    margin: 0 0 30px;
  }

  tr {
    border-bottom: 1px solid #cac8c8;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: rgba(220, 205, 178, 0.5);
    }
  }

  thead {
    tr {
      background-color: rgba(220, 205, 178, 0.3);

      &:last-child {
        border-bottom: 1px solid #cac8c8;
      }
    }
  }

  tbody {
    tr {

      &:nth-child(even) {
        background-color: rgba(220, 205, 178, 0.15);
        &:hover {
          background-color: rgba(220, 205, 178, 0.5);
        }
      }
    }
  }

  th {
    font-weight: bold;
    background-color: #ede8db;
  }

  th, td {
    width: 35px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    position: relative;
    border-right: 1px solid #cac8c8;
    font-size: 13px;

    &:last-child {
      border-right: none;
    }

    &:hover {
      background-color: rgba(220, 205, 178, 1);

      &::after {
        content: "";
        position: absolute;
        background-color: rgba(220, 205, 178, 0.5);
        left: 0;
        top: -5000px;
        height: 10000px;
        width: 100%;
        z-index: -1;
      }
    }

    @media (min-width: 768px) {
      width: 45px;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
    }
  }
}

.simple-chart__table {
  max-width: 520px;
  margin: 10px;
  border-radius: 0 0 10px 10px;

  @media (min-width: 768px) {
    margin: 30px;
  }

  tr {
    border-bottom: 1px solid #cac8c8;    
  }

  tbody {
    tr:last-child {
      border-bottom: none;
    }
    tr:nth-child(even) {
      background-color: rgba(220, 205, 178, 0.15);
    }
  }

  th, td {
    border-right: 1px solid #cac8c8;
    padding: 8px 12px;
    position: relative;
    font-size: 13px;
    line-height: 130%;
    
    &:last-child {
      border-right: none;
    }

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  th {
    padding: 8px;
    text-align: center;
    font-weight: bold;
    background-color: #ede8db;
    white-space: nowrap;
  }
}
