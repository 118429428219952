#search-form {
  background: #F7F4ED;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 15px;

  @media (min-width: 768px) {
    display: flex;
  }

  .row {
    flex: 1 1 auto;
    .field {
      margin: 0;
    }
    input {
      padding: 15px;
      line-height: 23px;
    }
  }
  .btn {
    width: 100%;
    flex: 0 0 200px;
  }
}

.search-container {
  background: #F7F4ED;
  padding: 20px;
  border-radius: 15px;
}

.table--search {
  width: 100%;
  text-align: left;
  tr {
    position: relative;
    overflow: hidden;
  }
  th {
    color: #222;
  }
  td, th {
    vertical-align: middle;
    &:nth-child(3) {
      text-align: center;
    }
    &:nth-child(4) {
      text-align: right;
    }
  }
  .btn {
    @media (max-width: 767px) {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }
}