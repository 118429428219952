.grid {
  display: flex;
  flex-flow: row wrap;
  display: grid;
  grid-template-columns: 1fr;
  margin: -0.5em -0.5em 2em;
}

.grid > * {
  flex: 1 1 100%;
  margin: 0.5em;
}

@media only screen and (min-width: 720px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid > * {
    flex: 1 1 40%;
  }
}

@media only screen and (min-width: 1366px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .grid > * {
    flex: 1 1 26%;
  }
}

@media only screen and (min-width: 1440px) {
  .grid {
    margin: -1em -1em 2em;
  }

  .grid > * {
    margin: 1em;
  }
}

@media only screen and (min-width: 1800px) {
  .grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .grid > * {
    flex: 1 1 21%;
  }
}

@media print {
  .grid {
    display: block;
    text-align: center;
    min-width: 735px;
  }

  .grid > * {
    display: inline-block;
    vertical-align: top;
    width: 350px;
    text-align: left;
  }
}
