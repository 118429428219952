.toggle-accordion {
  margin-bottom: 1em;
}

.toggle-accordion__header {
  transition: 220ms;
  font-size: 18px;
  padding: 0.5em 0.75em 0.5em 1em;
  background: #4B565E;
  color: #fff;
  display: block;
}

.toggle-accordion__header:hover {
  background: #5b6872;
}

.toggle-accordion__header:hover .toggle-control .toggle-control__inner:before {
  background: #fff;
}

.toggle-accordion__header .toggle-control {
  float: right;
  margin: 2px 0;
}

.toggle-accordion__inner {
  display: none;
  padding: 1em;
  background: rgba(0, 0, 0, 0.05);
}

.toggle-accordion--open .toggle-accordion__inner {
  display: block;
}

.toggle-control input {
  position: absolute;
  left: -999em;
}

.toggle-control__inner {
  display: block;
  width: 40px;
  border-radius: 20px;
  background: #2b323a;
  padding: 3px;
}

.toggle-control__inner:before {
  transition: 220ms;
  content: '';
  display: block;
  position: relative;
  left: 0;
  height: 16px;
  width: 16px;
  background: #9B9792;
  border-radius: 50%;
}

.toggle-control:hover .toggle-control__inner:before {
  background: #fff;
}

.toggle-control input:checked ~ .toggle-control__inner:before {
  background: #fff;
  left: 18px;
}