.deck {
  transition: 220ms;
  position: relative;
  height: 100%;
  max-width: 350px;
  text-decoration: none;
  display: block;
  background: none;
  font-family: inherit;
  border: none;
  padding: 0;
}

.deck__outer {
  padding: 1em;
}

.deck__container {
  height: 100%;
}

.deck__inner {
  transform-origin: 15px calc(100% - 15px);
  transition: 220ms;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 15px;
  z-index: 5;
  background: #2b323a;
  border: 15px solid #fff;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 30px;
  color: #bf7d26;
  min-height: 500px;
  height: 100%;
}

.deck__inner:before {
  content: '';
  position: absolute;
  top: 8px;
  left: 8px;
  bottom: 8px;
  right: 8px;
  border: 3px solid #5F5245;
}

.deck__inner:after {
  content: '';
  position: absolute;
  top: 14px;
  left: 14px;
  bottom: 14px;
  right: 14px;
  border: 3px solid #5F5245;
}

.deck__inner img {
  height: 250px;
  margin: 5px;
  align-self: center;
}

.deck__name {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  display: block;
}

.deck__influence, .deck__rating, .deck__warrior-count {
  display: flex;
  align-items: center;
  justify-content: center;
}

.deck__influence span, .deck__rating span, .deck__warrior-count span {
  width: 60%;
  text-align: right;
}

.deck__influence strong, .deck__rating strong, .deck__warrior-count strong {
  color: #fff;
  font-size: 24px;
  vertical-align: middle;
  margin-left: 10px;
  text-align: left;
  width: 40%;
}

.deck__cards {
  transition: 220ms;
  transform-origin: 15px calc(100% - 15px);
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 6px;
  left: -6px;
  z-index: 1;
  border-radius: 15px;
  background: #fff;
}

.deck__cards:before, .deck__cards:after {
  content: '';
  transition: 220ms;
  transform-origin: 15px calc(100% - 15px);
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  margin: -4px 0 0 4px;
  border-radius: 15px;
  background: #fff;
}

.deck__cards:after {
  margin: -2px 0 0 2px;
  z-index: 2;
}

a.deck:hover .deck__inner {
  transform: rotate(4deg);
}

a.deck:hover .deck__cards {
  transform: rotate(-2deg);
}

a.deck:hover .deck__cards:before {
  transform: rotate(4deg);
}

a.deck:hover .deck__cards:after {
  transform: rotate(2deg);
}
