.form__container {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.form__container .form__inner {
  flex: 1 1 auto;
  padding: 1em;
  overflow: auto;
}

@media only screen and (min-width: 600px) {
  .form__container .form__inner {
    padding: 1.5em;
  }
}

@media only screen and (min-width: 1440px) {
  .form__container .form__inner {
    padding: 2em;
  }
}

.form__container .form__footer {
  flex: 0 0 55px;
  background: rgba(0, 0, 0, 0.075);

  .btn {
    width: 50%;
    #edit-company & {
      width: 33.333%;
    }
  }

}

@media only screen and (min-width: 600px) {
  .form__container .form__footer .btn {
    width: auto;
    #edit-company & {
      width: auto;
    }
  }
}

.form__container .form__footer .btn--primary {
  float: right;
}

.form__container .form__footer .btn--destructive,
.form__container .form__footer .btn--constructive,
.form__container .form__footer .btn--secondary {
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
}

.row:last-child .field {
  margin-bottom: 0;
}

.row--modify-characteristics {
  overflow: hidden;
}

.row--modify-characteristics .field {
  float: left;
  width: 60%;
}

.row--modify-characteristics .field:first-child {
  padding-right: 10px;
  width: 40%;
}

.row--modify-casting-value {
  overflow: hidden;
}

.row--modify-casting-value .field {
  float: left;
  width: 50%;
}

.row--modify-casting-value .field:first-child {
  padding-right: 10px;
}

@media screen and (min-width: 600px) {
  .row--modify-casting-value {
    max-width: 300px;
  }
}

@media screen and (min-width: 980px) {
  .row--modify-characteristics .field:last-of-type label {
    display: none;
  }

  #result-overlay .row--modify-characteristics .field {
    width: 42%;
  }

  #result-overlay .row--modify-characteristics .field:first-child {
    width: 58%;
  }

  .row--modify-casting-value {
    max-width: 480px;
  }

  .row--modify-casting-value .field:last-of-type {
    width: calc(50% - 115px);
  }

  .row--modify-casting-value .field:last-of-type label {
    display: none;
  }

  .card-overlay .row--modify-casting-value .field {
    width: 50%;
  }

  .card-overlay .row--modify-casting-value .field:first-child {
    width: 50%;
  }

  .card-overlay .row--modify-casting-value .field:last-of-type label {
    display: block;
  }

}

.row--points {
  display: flex;
  align-items: center;
}

.row--points > span {
  margin: 1em 0.5em 0.5em;
  flex: 0;
}

.row--points .field {
  flex: 0;
}

@media screen and (min-width: 980px) {
  .row--points > span {
    margin: 0 0.5em 1em;
  }

  .row--points .field:first-of-type {
    flex: 0 0 170px;
  }

  .row--points .field:nth-of-type(2) label {
    display: none;
  }

  .row--base-stats {
    overflow: hidden;
  }
  .row--base-stats .field {
    float: left;
    width: calc(100% - 235px);
  }
  .row--base-stats .field:last-child {
    padding-left: 20px;
    width: 235px;
  }
}

.form__inner-footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
}
.row--cb-save {
  flex: 1 1 100%;
  text-align: right
}

.row--cb-save .checkbox {
  padding: 0 34px 0 0;
}

.row--cb-save .checkbox .checkbox__input {
  float: right;
  margin: 0 -34px 0 0;
}

@media screen and (min-width: 600px) {
  .row--cb-save {
    flex: initial;
    text-align: right
  }
  .row--cb-save .field {
    margin-bottom: 0;
  }
}

.field {
  margin-bottom: 1em;

  &--border {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    border-bottom: 1px solid #cac8c8;
    padding: 2px 10px;

    &:last-child {
      border-bottom: none;
    }

    label {
      flex: 1 0 auto;
    }
  }
}

.row--table table {
  min-width: 0;
  flex: 1;
}

#result-overlay .row--table table {
  flex: 1 1 100%;
  margin-top: 5px;
}

@media screen and (min-width: 980px) {
  .field {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
  }

  input, textarea, select {
    flex: 1 1;
    max-width: 100%;
  }

  .row--table .field label {
    align-self: flex-start;
    padding-top: 4px;
  }
}

label {
  font-size: 16px;
  font-weight: 600;
  display: block;
  padding-bottom: 5px;
}

@media screen and (min-width: 980px) {
  label {
    flex: 0 0 115px;
    padding-bottom: 0;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }
}

input,
textarea {
  font: inherit;
  width: 100%;
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #cac8c8;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
}

input.small {
  width: 50px;
  max-width: 50px;
  text-align: center;
}

#root select {
  display: block;
  font: inherit;
  font-size: 16px;
  line-height: 18px;
  padding: 8px 36px 8px 12px;
  width: 100%;
  max-width: 100%;
  border: 1px solid #cac8c8;
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNi4yIDE2LjIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDE2LjIgMTYuMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTguMSwxMi41Yy0wLjMsMC0wLjUtMC4xLTAuNy0wLjNMMC41LDUuNGMtMC40LTAuNC0wLjQtMSwwLTEuNHMxLTAuNCwxLjQsMGw2LjIsNi4ybDYuMi02LjJjMC40LTAuNCwxLTAuNCwxLjQsMA0KCQlzMC40LDEsMCwxLjRsLTYuOSw2LjlDOC42LDEyLjQsOC40LDEyLjUsOC4xLDEyLjV6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==');
  background-repeat: no-repeat;
  background-position: right 12px top 50%, 0 0;
  background-size: 16px;
}

#root select[multiple] {
  background-image: none;
}

select::-ms-expand {
  display: none;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

input:hover,
textarea:hover,
#root select:hover {
  border-color: #aaa;
}

input:focus,
textarea:focus,
select:focus {
  border-color: #de932f;
  outline: none;
}

option:disabled {
  padding: 0;
  line-height: 1px;
  display: block;
}

input[type="checkbox"] {
  width: auto;
}

textarea {
  height: 5em;
  &.large {
    height: 10em;
  }
}

.checkbox-list .checkbox {
  display: block;
}

@media screen and (min-width: 980px) {
  .checkbox-list {
    display: flex;
    flex-flow: row wrap;
    flex: 1;
  }
  .checkbox-list .checkbox {
    flex: 0 1 auto;
    margin-right: 16px;
  }
  .checkbox-list .checkbox:last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 1200px) {
  .checkbox-list .checkbox {
    margin-right: 25px;
  }
}

.checkbox {
  padding-left: 34px;
  line-height: 26px;
  overflow: hidden;
  margin: 3px 0;
  font-weight: normal;
  flex: 1 auto;
}

.checkbox input {
  position: absolute;
  left: -999em;
}

.checkbox__input {
  float: left;
  margin-left: -34px;
  background: #fff;
  border: 1px solid #cac8c8;
}

.checkbox:hover .checkbox__input {
  border-color: #aaa;
}

.checkbox__input:before {
  content: '';
  transition: 220ms;
  transform: scale(0.8) rotate(45deg);
  display: block;
  height: 18px;
  width: 10px;
  margin: 3px 8px 5px;
  border-bottom: 4px solid #de932f;
  border-right: 4px solid #de932f;
  opacity: 0;
}

.checkbox input:checked ~ .checkbox__input:before {
  transform: scale(1) rotate(45deg);
  opacity: 1;
}

.field-validation-error {
  flex: 1 1 100%;
  padding: 2px 0;
  color:  #a53939;
  font-size: 14px;
}


@media screen and (min-width: 980px) {
  .field .field-validation-error {
    padding-left: 115px;
  }
}

.text-page-form {
  padding-bottom: 3em;
}

.text-page-forms > .text-page-form {
  padding: 1.5em;
  margin-bottom: 2em;
  background: rgba(34, 40, 48, 0.8);
  border-radius: 10px;
}

.text-page-forms .text-page-form h3 {
  margin: 0 0 0.5em;
  font-weight: 300;
  font-size: 26px;
  color: #fff;
}

.text-page-form > span {
  font-weight: 600;
  padding: 3em 0 2em;
  text-align: center;
  display: block;
}

.text-page-form .form__inner {
  overflow: visible;
  padding: 0;
}

.text-page-form .form__inner input {
  font-size: 1.1em;
  padding: 12px 15px;
}

.text-page-form .field-validation-error {
  padding-left: 0;
  color: #ec5d5d;
}

.text-page-form .form__inner .row:last-child .field-validation-error {
  margin-bottom: -26px;
  float: left;
}

.text-page-form .form__footer .btn {
  padding: 14px 18px;
  margin-top: -47px;
  width: 132px;
}

.text-page-form .form__inner .row:last-child {
  width: calc(100% - 144px);
}

@media screen and (min-width: 1200px) {
  .text-page-forms {
    display: flex;
    justify-content: space-between;
  }

  .text-page-forms .text-page-form h3 {
    font-size: 32px;
  }

  .text-page-forms > .text-page-form {
    flex: 1 1 40%;
    max-width: calc(50% - 1em);
    padding: 2em;
    border-radius: 20px;
  }

  .text-page-forms > span {
    flex: 0 1 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.switch {
  display: flex;
  background: #2b323a;
  border-radius: 20px;
  position: relative;
  min-width: 150px;
  padding-bottom: 0;
}

@media only screen and (min-width: 980px) {
  .switch {
    min-width: 170px;
  }
}

.switch input {
  position: absolute;
  left: -999em;
}

.switch__value {
  transition: 220ms;
  position: relative;
  z-index: 2;
  width: 50%;
  padding: 6px;
  text-align: center;
  color: #5b6a72;
}

.switch__value:first-of-type {
  color: #fff;
}

.switch__inner {
  transition: 220ms;
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 1;
  display: block;
  width: calc(50% - 3px);
  height: calc(100% - 6px);
  border-radius: 20px;
  padding: 3px;
  background: #9B9792;
}

.switch input:checked ~ .switch__inner {
  background: #fff;
  left: 50%;
}

.switch input:checked ~ .switch__value {
  color: #2b323a;
}

.switch input:checked ~ .switch__value:first-of-type {
  color: #5b6a72;
}
