.overlay {
  visibility: hidden;
  opacity: 0;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(18, 25, 33, 0.75);
  align-items: center;
  justify-content: center;
}

.overlay--open {
  animation: overlay-in 220ms forwards;
  visibility: visible;
}

@keyframes overlay-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.overlay--open .overlay__inner {
  animation: overlay-inner-in 220ms forwards cubic-bezier(0.175, 0.885, 0.32, 1.2);
}

@keyframes overlay-inner-in {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

.overlay--small .overlay__inner {
  width: 550px;
}

.overlay__inner {
  transition: 220ms cubic-bezier(0.175, 0.885, 0.32, 1.2);
  transform: scale(0.8);
  max-width: 100%;
  max-height: 100%;
  position: relative;
  background: #2b323a;
  padding: 1em;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.4);
  overflow: auto;
  border-radius: 15px;
}

@media screen and (min-width: 600px) {
  .overlay__inner {
    padding: 1em 1.5em 1.5em;
    max-width: calc(100vw - 2em);
    max-height: calc(100vh - 2em);
    border-radius: 30px;
  }
}

@media screen and (min-width: 1440px) {
  .overlay__inner {
    padding: 1.5em 2.5em 2.5em;
  }
}

.overlay__close {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  font-size: 1.75em;
  padding: 0.2em;
  color: #fff;
  background: none;
  outline: none;
  border: none;
}

.overlay__close svg {
  display: block;
}

.overlay__content {
  overflow: hidden;
  background: #F7F4ED;
  border-radius: 15px;
}

.overlay__content__split {
  margin: 0.5em 0;
}

@media screen and (min-width: 600px) {
  .overlay__content__split {
    display: flex;
  }
}

@media screen and (min-width: 600px) {
  .overlay__content__left {
    flex: 1 1 auto;
  }
}

.overlay__content__right {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 1em;
}

.overlay__content__right h3 {
  margin: 1em 0 0;
}

@media screen and (min-width: 600px) {
  .overlay__content__right {
    display: block;
    flex: 1 1 40%;
    padding-top: 0;
    padding-left: 1em;
  }
  .overlay__content__right h3 {
    margin: 0 0 1em;
  }
}

.overlay__content p {
  margin-top: 0;
}

.overlay__table {
  margin: 0 auto;
}

@media only screen and (min-width: 600px) {
  .overlay__table {
    margin: 0;
  }
}

.overlay__table tr {
  border: 1px solid #cac8c8;
}

.overlay__table tr:nth-child(even) {
  background: none;
}

.overlay__table tr.total {
  background: #fff;
}

.overlay__table tr.total th {
  font-size: 18px;
}

.overlay__table tr.total td {
  font-size: 28px;
}

.overlay__table th {
  padding: 8px 12px;
  text-align: left;
  font-size: 14px;
}

.overlay__table td {
  text-align: center;
  padding: 0;
  vertical-align: middle;
  font-size: 20px;
  font-weight: bold;
  height: 40px;
  color: #222;
  min-width: 50px;
}

.overlay__table td input {
  text-align: center;
  border: none;
  border-left: 1px solid #cac8c8;
  font-size: 20px;
  font-weight: bold;
  padding: 0;
  line-height: 40px;
  width: 80px;
}

.overlay h1 {
  margin: 0.25em 0 0.75em;
  color: #fff;
  font-size: 26px;
}

.overlay .edit-left {
  overflow: hidden;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  height: 100%;
  position: relative;
}

.overlay .edit-left.submitting {
  opacity: 0.7;
}

.overlay .submit-block {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
}

.overlay .edit-left .tabs {
  flex: 1 1;
  border-radius: 0 0 15px 15px;
  overflow: hidden;
}

/* Overlays =================================================== */
#edit-rule .overlay__inner,
#edit-spell .overlay__inner,
#edit-wargear .overlay__inner {
  width: 680px;
}

#edit-wargear, #edit-warrior {
  .row--modify-special-attack, .row--modify-rule {
    margin-bottom: 1em;
    & > .row > .field {
      align-items: flex-start;

      label {
        margin-top: .5em;
      }

      & > div {
        flex-grow: 1;

        .row {
          margin-bottom: 1em;
        }
      }
    }
  }
}

#edit-company .overlay__inner {
  width: 920px;
}

#edit-warrior .overlay__inner {
  width: 1220px;
}

#edit-company,
#edit-warrior,
#tools {
  .overlay__content {
    background: none;
    border-radius: 0 0 15px 15px;
  }
}

#edit-company .edit-right {
  margin-top: 1em;
}

#edit-warrior .edit-left .tabs--invert {
  height: auto;
  border-radius: none;
}

#edit-warrior .edit-left .tabs--invert .tabs__content {
  border-radius: 0 0 15px 15px;
}

#edit-warrior .edit-left .tabs--invert .table--stats {
  border-right-color: #fff;
}

#edit-warrior .edit-left .tabs--invert .table--stats thead tr th:first-child {
  background: #fff;
}

@media only screen and (min-width: 600px) {
  #edit-company .edit-left .tabs {
    height: 490px;
    min-height: 490px;
    max-height: 490px;
  }

  #edit-warrior .edit-left .tabs {
    height: 615px;
    min-height: 615px;
    max-height: 615px;
  }

  #edit-warrior .edit-left .tabs .tabs {
    height: auto;
    min-height: 0;
    max-height: none;
  }

  #edit-company .edit-right {
    display: none;
  }
}

@media only screen and (min-width: 980px) {
  #edit-company .overlay__content,
  #edit-warrior .overlay__content {
    display: flex;
  }

  #edit-company .edit-left .tabs,
  #edit-warrior .edit-left .tabs {
    border-radius: 0 0 0 15px;
  }

  #edit-company .edit-right {
    margin-top: 0;
    display: block;
    flex: 0 0 320px;
    padding-bottom: 6px;
  }

  #edit-warrior .edit-left .tabs--invert .tabs__content {
    border-radius: 0 15px 15px 15px;
  }
}

#edit-warrior .edit-left .table--stats {
  border-right: 1em solid #F7F4ED;
  width: 100%;
  margin-bottom: 2em;
}

#edit-warrior .edit-left .tabs--invert .table--stats {
  border-right: none;
}

#edit-warrior .edit-left .table--stats thead tr th:first-child,
#edit-warrior .edit-left .table--stats tbody tr td:first-child {
  text-align: left;
  width: 110px;
  border-right: none;
}

#result-overlay .table--stats thead tr th input,
#result-overlay .table--stats tbody tr td input,
#edit-wargear .table--stats thead tr th input,
#edit-wargear .table--stats tbody tr td input,
#edit-warrior .edit-left .table--stats thead tr th input,
#edit-warrior .edit-left .table--stats tbody tr td input {
  display: inline-block;
  width: 25px;
  padding: 0;
  text-align: center;
}

#edit-warrior .edit-left .table--stats thead tr th:first-child {
  background: #F7F4ED;
}

#edit-warrior .edit-right {
  margin-top: 1em;
}

#edit-warrior .edit-left .accordion .table--stats {
  border-right: none;
  min-width: 0;
  flex: 1;
  margin-bottom: 0;
}

#edit-warrior .edit-left .accordion .table--stats thead tr th:first-child,
#edit-warrior .edit-left .accordion .table--stats tbody tr td:first-child {
  text-align: center;
  width: auto;
  border-right: 1px solid #cac8c8;
  background: none;
}

@media only screen and (min-width: 600px) {
  #edit-warrior .edit-right {
    display: none;
  }
}

@media only screen and (min-width: 980px) {
  #edit-warrior .edit-right {
    margin-top: 0;
    display: block;
    flex: 0 0 360px;
  }

  #edit-warrior .edit-left .accordion .table--stats {
    max-width: calc(100% - 110px);
  }
}

#result-overlay .overlay__content {
  background: none;
  border-radius: 0;
}

#result-overlay .tabs__content {
  border-radius: 0 0 15px 15px;
  overflow: hidden;
}

#result-overlay .overlay__content .checkbox {
  flex: 1 1 100%;
  margin-right: 0;
}

#wargear-overlay .tabs__content {
  border-radius: 0 0 15px 15px;
}



#wargear-details table {
  min-width: 0;
  width: 100%;
}

@media only screen and (min-width: 1025px) {
  .form__container * {
    scrollbar-width: 1em;
    scrollbar-color: rgba(0, 0, 0, 0.15) #E4E2DB;
  }

  .form__container ::-webkit-scrollbar-track {
    background: #E4E2DB;
  }
  .form__container ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.15);
    border: 4px solid #E4E2DB;
  }
  .form__container ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.25);
  }
}

#support-us .overlay__content {
  padding: 0.5em 1em 1em;
}

#support-us .support-buttons {
  text-align: center;
  margin-top: 25px;
}

@media only screen and (min-width: 600px) {
  #support-us .overlay__content {
    padding: 0.5em 2em 2em;
  }

  #support-us .support-buttons {
    display: flex;
    align-items: center;
  }
}

#support-us .support-buttons strong{
  margin: 5px 10px;
  display: block;
}

#support-us .support-buttons .donate{
  max-width: 180px;
}

#support-us .support-buttons form img {
  position: absolute;
}
