.card {
  transition: 220ms;
  max-width: 420px;
  perspective: 2000px;
  position: relative;
  background: none;
  border: none;
}

.blood {
  position: absolute;
  opacity: 0.6;
  width: 75%;
  z-index: 3;
}

.card--overlay {
  visibility: hidden;
  perspective: initial;
  z-index: 20;
}

.card--overlay:first-child {
  visibility: visible;
}

.card--overlay:first-child .card__inner {
  animation: overlay-card-in 400ms forwards;
  transform-origin: 50% 100%;
}

.card--overlay.card--animate-out:first-child .card__inner {
  animation: overlay-card-out 400ms forwards;
  transform-origin: 50% 100%;
}

@keyframes overlay-card-in {
  0% {
    transform: translateX(50%) translateY(-20%) rotate(10deg);
    opacity: 0;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
    opacity: 1;
  }
}

@keyframes overlay-card-out {
  0% {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: translateX(-150%) translateY(-20%) rotate(-10deg);
    opacity: 0;
  }
}

.card--overlay .card__inner {
  transform: translateX(-50%) translateY(-50%);
  position: fixed;
  max-width: 95vw;
  width: 420px;
  height: 700px;
  max-height: 100%;
  top: 50%;
  left: 50%;
  z-index: 2;
  overflow: auto;
  padding: 10px 0;
}

.card--clickable {
  cursor: pointer;
}

.card--clickable:hover {
  transform: scale(1.02);
}

.card--clickable:hover .card__front {
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.4);
}

.card--highlight {
  transform: scale(1.1);
}

.card--flip {
  -webkit-animation: flip-parent 750ms forwards;
  animation: flip-parent 750ms forwards;
}

.card--flip .card__inner {
  -webkit-animation: flip 750ms forwards cubic-bezier(0.175, 0.885, 0.32, 1.2);
  animation: flip 750ms forwards cubic-bezier(0.175, 0.885, 0.32, 1.2);
}

.card--flip .card__rank {
  color: transparent;
}

.card--flip .card__back {
  z-index: 3;
}

.card--flip:hover {
  -webkit-animation: unflip-parent 750ms forwards;
  animation: unflip-parent 750ms forwards;
  z-index: 10;
}

.card--flip:hover .card__inner {
  -webkit-animation: unflip 750ms forwards cubic-bezier(0.175, 0.885, 0.32, 1.2);
  animation: unflip 750ms forwards cubic-bezier(0.175, 0.885, 0.32, 1.2);
}

.card--flip:hover .card__rank {
  color: #fff;
}

.card__inner {
  position: relative;
  transform-style: preserve-3d;
  height: 100%;
  display: flex;
  flex-flow: column;
}

.card__front {
  transition: 220ms;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  min-height: 600px;
  border-radius: 15px;
  background: #fff;
  padding: 0.5em 1em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
  z-index: 2;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
}

.card--wargear .card__front {
  min-height: 200px;
}

.card--wargear table {
  margin-top: 5px;
}

.card--rule .card__front {
  min-height: 200px;
}

.card__back {
  transform: rotateY(180deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  top: 28px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: #2b323a;
  border-radius: 15px;
  border: 15px solid #fff;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 10% 0;
}

.card__back:before {
  content: '';
  position: absolute;
  top: 8px;
  left: 8px;
  bottom: 8px;
  right: 8px;
  border: 3px solid #5F5245;
}

.card__back:after {
  content: '';
  position: absolute;
  top: 14px;
  left: 14px;
  bottom: 14px;
  right: 14px;
  border: 3px solid #5F5245;
  background: url(../../_assets/card-bg.svg) 50% 50% no-repeat;
  background-size: contain;
}

.card__back span {
  text-align: center;
  font-size: 24px;
  color: #927965;
  display: block;
  position: relative;
  z-index: 2;
}

.card__bottom .injuries {
  display: none;
}

.card__back span:first-child {
  font-size: 32px;
}

.card__back .blood {
  top: 30%;
  left: 20px;  
}

.card__rank {
  flex: 0 0 28px;
  align-self: flex-start;
  margin-left: 1.5em;
  font-weight: 600;
  font-size: 0.8em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 280px;
  background: #425562 linear-gradient(to left, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.3));
}

.card__rank--warrior {
  visibility: hidden;
}

.card__rank--leader {
  background: #ffb000 linear-gradient(to left, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.3));
}

.card__rank--sergeant {
  background: #cac0cc linear-gradient(to left, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.3));
}

.card__rank--hero {
  background: #9b6a54 linear-gradient(to left, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.3));
}

.card__header {
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  margin-bottom: 0.25em;
  overflow: hidden;
}

.card__header__name {
  float: left;
  width: calc(100% - 60px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card__header__points {
  float: right;
  width: 55px;
  text-align: right;
}

.card__header__points small {
  font-size: 0.7em;
  color: #9B9792;
  font-weight: normal;
}

.card__image {
  position: relative;
  height: 192px;
  background-position: 50% 50%;
  background-color: #eee;
  background-size: cover;
}

.card__divider {
  border-bottom: 2px solid #eee;
  margin-bottom: 10px;
}

.card__image .blood {
  transform: rotateY(180deg);
  top: -30px;
  right: -10px;
}

.card__image .injuries {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 2;
  overflow: hidden;
}

.card__image .injury {
  float: left;
  margin-right: 10px;
  border-radius: 50%;
  color: #fff;
  background: rgba(120, 0, 0, 0.8);
  border: 2px solid rgba(0, 0, 0, 0.2);
  padding: 0.2em;
  font-size: 2em;
}

.card__image .injury svg {
  display: block;
}

.card__image .btn--primary {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  padding: 0.5em;
  font-size: 15px;
}

.card__image .btn--primary svg { 
  margin: 0 0 0 0.25em
}

.card__details {
  font-size: 0.7em;
  display: flex;
  justify-content: space-between;
  padding: 0.5em 0;
}

.card__details__class {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 1 50%;
}

.card__details__path {
  flex: 1 1 40%;
  text-align: right;
}

.card .table--stats {
  width: 100%;
  min-width: 0;
}

.card .table--stats thead tr th {
  padding: 0.25em 0.5em;
}

.card .table--stats tbody tr td {
  padding: 0.25em 0.5em;
}

.card .table .number-control {
  flex: 0 0 100%;
  width: 100%;
}

.card__rules__container {
  display: flex;
  flex: auto;
}

.card__rules__container__left {
  display: flex;
  flex: auto;
  flex-flow: column;
}

.card__rules__container__right {
  display: flex;
  flex: 0 0 auto;
  margin-left: 1em;
  flex-flow: column;
}

.card__bottom {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #E6E6E6;
  margin: 0.5em -1em 0;
  padding: 0.5em 1em 0;
}

.card .rosette {
  margin-top: 35px;
}

.card .grow-in {
  animation: grow-in 500ms forwards;
}

.card .grow-out {
  animation: grow-out 500ms forwards;
}

.card .shrink-in {
  animation: shrink-in 500ms forwards;
}

.card .shrink-out {
  animation: shrink-out 500ms forwards;
}

.card .form__container {
  flex: 1;
  margin-bottom: 1em;
  background: #F7F4ED;
  overflow: hidden;
}

.card .form__container:last-child {
  margin-bottom: 0.5em;
  border-radius: 0 0 10px 10px;
}

.card .form__container .form__inner {
  padding: 1em;
}

.card .form__container h3 {
  margin: -1rem -1rem 1rem -1rem;
  padding: 1rem;
  background: rgba(220,205,178,.3);
}

.card .form__container .field {
  display: block;
}

.card .form__container .radio-list {
  display: block;
}

.card .form__container .radio-list .radio {
  margin: 8px 0;
}

.card .form__container .field .field-validation-error {
  padding-left: 0;
}

.card__experience {
  margin-bottom: 5px;
}

.card__experience__level {
  float: left;
  font-size: 22px;
  text-align: center;
  font-weight: bold;
  line-height: 34px;
  width: 40px;
  height: 40px;
  background: url(../../_assets/icons/experience.svg) 50% 50% no-repeat;
  background-size: 100%;
}

.card__experience .experience-bar-title {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  display: block;
  margin-left: 42px;
}

.card__experience .experience-bar {
  background: #f1f1f1;
  height: 15px;
  margin-left: 20px;
}

.card__experience .experience-bar__inner {
  transition: width 220ms;
  height: 15px;
  width: 0;
  background: linear-gradient(to right, #734200, #FBC300);
}

.card__experience .experience-bar--1 .experience-bar__inner {
  width: 20%;
}

.card__experience .experience-bar--2 .experience-bar__inner {
  width: 40%;
}

.card__experience .experience-bar--3 .experience-bar__inner {
  width: 60%;
}

.card__experience .experience-bar--4 .experience-bar__inner {
  width: 80%;
}

.card__experience .experience-bar--5 .experience-bar__inner {
  width: 100%;
}

.card__counters {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.card-counter {
  position: relative;
  flex: 0 0 122px;
  max-width: 122px;
  align-items: center;
}

.card-counter__controls {
  display: flex;
  float: left;
  margin: 1px 0;
}

.card-counter__dec, .card-counter__inc {
  transition: 220ms;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background: #f1f1f1;
  border: 1px solid #E6E6E6;
  cursor: pointer;
  padding: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  color: #4D4D4D;
}

.card-counter__dec:hover, .card-counter__inc:hover {
  background: #e4e2e2;
}

.card-counter__dec:disabled, .card-counter__inc:disabled {
  background: transparent;
  color: #cac8c8;
  cursor: default;
}

.card-counter__dec:disabled:hover, .card-counter__inc:disabled:hover {
  background: transparent;
}

.card-counter__dec svg, .card-counter__inc svg {
  font-size: 0.75em;
  margin: 0 auto;
}

.card-counter__inc {
  border-radius: 0 50% 50% 0;
}

.card-counter__dec {
  border-radius: 50% 0 0 50%;
  margin-right: -1px;
}

.card-counter > svg {
  font-size: 26px;
  margin-top: 4px;
  color: #A0836E;
  float: left;
}

.card-counter__val {
  font-size: 34px;
  font-weight: bold;
  min-width: 30px;
  text-align: center;
  margin-right: 3px;
  float: left;
  line-height: 30px;
}

.card-counter__title {
  font-size: 13px;
  line-height: 14px;
  font-weight: 600;
  float: left;
  clear: both;
  width: 100%;
  text-align: center;
  margin-bottom: 3px;
}

.marker-circles {
  display: none;
  line-height: 15px;
}

.card__bottom .marker-circles {
  max-width: 60px;
  margin: 0 5px;
}

.flee-container .btn {
  padding: 1px 5px;
  font-size: 12px;
  display: flex;
  flex-flow: column;
  align-items: center;
  border-radius: 10px;
  background: #f1f1f1;
  border: 1px solid #ddd;
}

@media screen and (min-width: 600px) {
  .flee-container .btn {
    padding: 1px 10px;
  }
}

.flee-container .btn:hover {
  background: #de932f;
  border-color: #de932f;
  color: #fff;
}

.flee-container .btn svg {
  color: currentColor;
  font-size: 2em;
  margin: 0 0 0.1em 0;
}

.marker-circle {
  display: inline-block;
  border: 1px solid #aaa;
  border-radius: 10px;
  height: 10px;
  width: 10px;
  margin: 0 2px 0;
}

.marker-circles__experience {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  width: 315px;
}

.marker-circles__experience .marker-circle {
  width: 8px;
  height: 8px;
  margin: 0 1px 0 0;
}

.marker-circles__experience .marker-circle--filled {
  border-width: 4px;
  border-color: #bbb;
}

.marker-circles__experience .marker-circle:nth-of-type(5n) {
  border-color: #A0836E;
}

.injury-status {
  position: absolute;
  top: 0;
  right: 0;
  color: #af1012;
  padding: 0 1em;
  font-weight: 600;
}

@-webkit-keyframes flip-parent {
  0% {
    z-index: 1;
  }
  1% {
    z-index: 2;
  }
  99% {
    z-index: 2;
  }
  100% {
    z-index: 1;
  }
}
@keyframes flip-parent {
  0% {
    z-index: 1;
  }
  1% {
    z-index: 2;
  }
  99% {
    z-index: 2;
  }
  100% {
    z-index: 1;
  }
}

@-webkit-keyframes unflip-parent {
  0% {
    z-index: 1;
  }
  1% {
    z-index: 2;
  }
  99% {
    z-index: 2;
  }
  100% {
    z-index: 1;
  }
}

@keyframes unflip-parent {
  0% {
    z-index: 1;
  }
  1% {
    z-index: 2;
  }
  99% {
    z-index: 2;
  }
  100% {
    z-index: 1;
  }
}

@-webkit-keyframes flip {
  0% {
    transform: rotateY(0deg) scale(1) translateX(0) translateY(0);
  }
  70% {
    transform: rotateY(180deg) scale(1.1) translateX(20px) translateY(10px);
  }
  100% {
    transform: rotateY(180deg) scale(1) translateX(0) translateY(0);
  }
}

@keyframes flip {
  0% {
    transform: rotateY(0deg) scale(1) translateX(0) translateY(0);
  }
  70% {
    transform: rotateY(180deg) scale(1.1) translateX(20px) translateY(10px);
  }
  100% {
    transform: rotateY(180deg) scale(1) translateX(0) translateY(0);
  }
}

@-webkit-keyframes unflip {
  0% {
    transform: rotateY(180deg) scale(1) translateX(0) translateY(0);
  }
  70% {
    transform: rotateY(0deg) scale(1.1) translateX(-20px) translateY(10px);
  }
  100% {
    transform: rotateY(0deg) scale(1) translateX(0) translateY(0);
  }
}

@keyframes unflip {
  0% {
    transform: rotateY(180deg) scale(1) translateX(0) translateY(0);
  }
  70% {
    transform: rotateY(0deg) scale(1.1) translateX(-20px) translateY(10px);
  }
  100% {
    transform: rotateY(0deg) scale(1) translateX(0) translateY(0);
  }
}

@-webkit-keyframes grow-in {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes grow-in {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes grow-out {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
}

@keyframes grow-out {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
}

@-webkit-keyframes shrink-in {
  0% {
    opacity: 0;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes shrink-in {
  0% {
    opacity: 0;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes shrink-out {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes shrink-out {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@media print {  
  .card {
    transform: none !important;
    height: calc(50vh - 8em);
    min-height: 500px;
    margin: 0.5em;
  }

  .card--flip,
  .card--flip:hover,
  .card--flip .card__inner,
  .card--flip:hover .card__inner {
    -webkit-animation: none;
    animation: none;
  }

  .card--flip .card__rank {
    color: #fff;
  }

  .card:nth-child(4n+1) {
    page-break-before: always;
  }

  .card__rank {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: none;
  }

  .card__front {
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    min-height: 0;
    height: 100%;
  }

  .card__header {
    margin-bottom: 0;
    flex: 0 0 auto;
  }

  .card__header .injuries {
    display: inline-block;
  }

  .card__header .injury {
    float: left;
    margin-left: 5px;
    color: #c11111;
  }
  
  .card__header .injury svg {
    display: block;
  }

  .card__details {
    padding-top: 0;
  }

  .card__image {
    display: none;
  }

  .card table,
  .card .card__rules {
    margin-bottom: 0.5em;
  }

  .card__back {
    display: none;
  }

  .card__experience__level {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    background: #fff;
    position: relative;
    z-index: 20;
  }

  .card__experience .experience-bar {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  
  .card__experience .experience-bar__inner {
    border: 1px solid rgba(0, 0, 0, 0.4);
    margin: -1px 0;
  }

  .card .marker-circles {
    display: block;
    max-width: none;
  }

  .card .card__bottom .card-counter {
    text-align: center;
  }

  .card .card__bottom .card-counter svg {
    margin-right: 5px;
    margin-top: 0;
    float: none;
    display: inline-block;
    vertical-align: middle;
  }

  .card .card__bottom .marker-circles {
    min-height: 32px;
    display: inline-flex;
    flex-flow: row wrap;
    align-items: center;
    width: auto;
    max-width: 80px;
    vertical-align: middle;
  }

  .card-counter__title {
    float: none;
    display: block;
  }

  .card-counter__val {
    visibility: hidden;
  }

  .card-counter__controls {
    display: none;
  }

  .card .number-control {
    display: none;
  }
}