.card__rules {
  margin-bottom: 1em;
  font-size: 12px;
  flex: auto;
  display: flex;
  flex-flow: column;
}

.card__rules__header {
  flex: 0 0 26px;
  align-self: flex-start;
}

.card__rules__inner {
  padding: 0.5em 1em;
  flex: auto;
}

.card__rules--wargear .card__rules__header {
  background: #af9895;
}

.card__rules--wargear .card__rules__inner {
  background: #e8e0df;
}

.card__rules--special-rules .card__rules__header {
  background: #95b193;
}

.card__rules--special-rules .card__rules__inner {
  background: #d8e2d7;
}

.card__rules--attacks .card__rules__header {
  background: #959daf;
}

.card__rules--attacks .card__rules__inner {
  background: #dfe1e8;
}

.card__rules--heroic-actions .card__rules__header {
  background: #d3c07c;
}

.card__rules--heroic-actions .card__rules__inner {
  background: #f0ecd9;
}

.card__rules--spells .card__rules__header {
  background: #959daf;
}

.card__rules--spells .card__rules__inner {
  background: #dfe1e8;
}

.card__rules__item {
  font-family: inherit;
  background: none;
  border: none;
  padding: 0;
  cursor: inherit;
}

.card__rules--heroic-actions .card__rules__item {
  display: block;
  white-space: nowrap;
}

.card__rules--heroic-actions .card__rules__item::after {
  display: none;
}

.card__rules--spells .card__rules__item {
  display: block;
  white-space: nowrap;
}

.card__rules--spells .card__rules__item::after {
  display: none;
}

.card__rules__item--clickable:hover {
  text-decoration: underline;
  cursor: pointer;
}

.card__rules__item::after {
  content: ',';
  margin-right: 3px;
}

.card__rules__item:last-child::after {
  display: none;
}

@media print {
  .card__rules__header {
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: -1px;
    position: relative;
    z-index: 2;
  }

  .card__rules__inner {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
}