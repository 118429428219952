.tabs {
  display: flex;
  flex-flow: column;
}

.tabs .tabs__controls {
  padding: 0;
  margin: 0;
  display: flex;
  overflow: auto;
}

@media only screen and (min-width: 600px) {
  .tabs .tabs__controls {
    padding: 0 40px 0 0;
    overflow: visible;
  }
}

.tabs .tabs__controls li {
  flex: 0 0 auto;
  list-style: none;
  margin-right: 5px;
}

.tabs .tabs__controls li.active button {
  background: #F7F4ED;
  color: #2b323a;
  border-radius: 10px 10px 0 0;
  padding-bottom: 1em;
  margin-bottom: 0;
}

.tabs .tabs__controls li.active button:hover {
  background: #F7F4ED;
}

.tabs .tabs__controls li button {
  border-radius: 10px;
  color: #fff;
  padding: 0.5em 1em;
  margin-bottom: 5px;
  display: block;
  text-decoration: none;
  text-align: center;
  position: relative;
  background: #4B565E;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  font-family: inherit;
  border: none;
  display: block;
  outline: none;
}

.tabs .tabs__controls li button svg {
  margin: 0 10px 0px -20px;
  width: 30px;
  height: 30px;
  vertical-align: middle;
}

.tabs .tabs__controls li  button:hover {
  background: #5b6872;
}

@media only screen and (min-width: 1440px) {
  .tabs .tabs__controls li button {
    font-size: 15px;
    padding: 0.5em 1em;
  }

  .tabs .tabs__controls--large li button {
    font-size: 20px;
    padding: 0.5em 1em;
  }

  .tabs .tabs__controls li button svg {
    margin: 0 10px 0px -30px;
    width: 40px;
    height: 40px;
  }
}

.tabs .tabs__controls--stretch {
  padding: 0;
}

.tabs .tabs__controls--stretch li {
  flex: 1 1 auto;
}

.tabs .tabs__controls--stretch li:last-child {
  margin-right: 0;
}

.tabs .tabs__controls--stretch li button {
  width: 100%;
}

.tabs .tabs__content {
  display: none;
  background: #F7F4ED;
  overflow: auto;
  flex: 1 1;
}

.tabs .tabs__content--active {
  display: flex;
  flex-direction: column;
}

.tabs .tabs__content--active .form__container {
  flex: 1;
  overflow: auto;
}

.tabs--invert .tabs__controls li.active button {
  background: #fff;
  border-bottom-color: #fff;
}

.tabs--invert .tabs__controls li.active button:hover {
  background: #fff;
}

.tabs--invert .tabs__controls li button {
  background: #4B565E;
}

.tabs--invert .tabs__content {
  background: #fff;
  padding: 1em 1em 0;
}

@media screen and (min-width: 600px) {
  .tabs--invert .tabs__content {
    padding: 1.5em 1.5em 0.5em;
  }
}

.tabs--text .tabs__controls li.active button {
  background: rgba(34, 40, 48, 0.8);
  color: #fff;
}

.tabs--text .tabs__controls li.active button:hover {
  background: rgba(34, 40, 48, 0.8);
}

.tabs--text .tabs__controls li button {
  background: #4B565E;
}

.tabs--text .tabs__content {
  background: rgba(34, 40, 48, 0.8);
  padding: 1em;
  border-radius: 0 20px 20px 20px;
}

@media screen and (min-width: 600px) {
  .tabs--text .tabs__content {
    padding: 2em;
  }
  
  .tabs--text .tabs__controls li button {
    font-size: 1.3em;
  }
}