.right {
  margin-left: 15px;  
  float: right;
  text-align: center;
  width: 170px;
}

.right strong {
  display: block;
  margin: 15px 0 10px;
}

.donate {
  transition: 150ms;
  display: inline-block;
}

.donate input {
  border: none;
  padding: 0;
}

@media screen and (min-width: 600px) {
  .right {
    margin-top: -75px;
    margin-left: 50px;
    width: 180px;
  }

  .donate {
    margin: 0;
  }
}