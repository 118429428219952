/* Reset */
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
  display: block; }

audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

audio:not([controls]) {
  display: none; }

[hidden] {
  display: none; }

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

html, button, input, select, textarea {
  font-family: sans-serif;
  color: #222; }

body {
  margin: 0;
  font-size: 1em;
  line-height: 1.4; }

::-moz-selection {
  background: #6b959d;
  color: #fff;
  text-shadow: none; }

::selection {
  background: #de932f;
  color: #fff;
  text-shadow: none; }

a:hover, a:active {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

blockquote {
  margin: 1em 40px; }

dfn {
  font-style: italic; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

ins {
  background: none;
  color: #000;
  text-decoration: none; }

mark {
  background: #ff0;
  color: #000;
  font-style: italic;
  font-weight: bold; }

pre, code, kbd, samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: none; }

q:before, q:after {
  content: "";
  content: none; }

small {
  font-size: 85%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

ul, ol {
  margin: 1em 0;
  padding: 0 0 0 40px; }

dd {
  margin: 0 0 0 40px; }

nav ul, nav ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

form {
  margin: 0; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

label {
  cursor: pointer; }

legend {
  border: 0;
  *margin-left: -7px;
  padding: 0;
  white-space: normal; }

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

button, input {
  line-height: normal; }

button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
  *overflow: visible; }

button[disabled], input[disabled] {
  cursor: default; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  *width: 13px;
  *height: 13px; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

input:invalid, textarea:invalid {
  background-color: #f0dddd; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td {
  vertical-align: top; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: black;
  padding: 0.2em 0; }

/* =============================================================================
   Non-Semantic Helper Classes
   ========================================================================== */
.ir {
  display: block;
  border: 0;
  text-indent: -999em;
  overflow: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr;
  *line-height: 0; }

.ir br {
  display: none; }

.hidden {
  display: none; }

.js .jsHide {
  display: none; }

.no-js .noJsHide {
  display: none; }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.invisible {
  visibility: hidden; }

.clearfix:before, .clearfix:after, .container:before, .container:after {
  content: '.';
  display: block;
  overflow: hidden;
  visibility: hidden;
  font-size: 0;
  line-height: 0;
  width: 0;
  height: 0; }

.clearfix:after, .container:after {
  clear: both; }

.clearfix, .container {
  zoom: 1; }

.clear {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0; }

/* =============================================================================
   Print Styles
   ========================================================================== */
@media print {
  .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {
    content: ""; }
  /* Don't show links for images, or javascript/internal links */
  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  /* h5bp.com/t */
  tr, img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p, h2, h3 {
    orphans: 3;
    widows: 3; }
  h2, h3 {
    page-break-after: avoid; }
  .advert-container {
    display: none !important; } }

/* Styles =================================================== */
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  width: 100%;
  min-width: 300px;
  background: #00060e url(./_assets/bg.jpg) 0 0 no-repeat fixed;
  background-size: cover;
  color: #222;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px; }

@media only screen and (min-width: 980px) {
  #root {
    display: flex; } }

@media only screen and (min-width: 1025px) {
  * {
    scrollbar-width: 1em;
    scrollbar-color: rgba(255, 255, 255, 0.5) #434850; }
  ::-webkit-scrollbar {
    width: 1em; }
  ::-webkit-scrollbar-track {
    background: #414850;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1); }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0.5em;
    border: 4px solid #414850;
    cursor: pointer; }
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.75); } }

#wrapper {
  width: 100%;
  overflow: hidden;
  margin-top: 56px;
  padding: 1em 1em 3em;
  position: relative; }

#wrapper #wrapper-inner {
  transition: transform 350ms;
  transform: none; }

@media only screen and (min-width: 980px) {
  #wrapper {
    margin-top: 0; } }

@media only screen and (min-width: 1440px) {
  #wrapper {
    padding: 2em; } }

/* Typography =================================================== */
h1, h2, h3, h4, h5, h6 {
  line-height: 100%;
  font-weight: 600; }

#main > h1 {
  color: #fff;
  font-size: 24px;
  line-height: 24px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  margin-top: 0;
  margin-bottom: 40px;
  background: #2b323a;
  padding: 22px 20px; }

#main > h2 {
  color: #de9330;
  font-weight: 600;
  font-size: 1.4em; }

#main > h2 svg {
  margin: 3px 5px 0 0; }

ul {
  padding-left: 1em; }

ul li {
  color: #de932f; }

ul li span {
  color: #222; }

a {
  transition: color 220ms, background-color 220ms; }

strong {
  font-weight: 600; }

img {
  max-width: 100%;
  height: auto; }

/* Main =================================================== */
#main {
  zoom: 1;
  min-height: 80vh; }

#main:before, #main:after {
  content: '.';
  display: block;
  overflow: hidden;
  visibility: hidden;
  font-size: 0;
  line-height: 0;
  width: 0;
  height: 0; }

#main:after {
  clear: both; }

.header-tab, .card__rank, .card__rules__header {
  border-radius: 10px 10px 0 0;
  padding: 0.4em 1em;
  display: inline-block;
  color: #fff; }

.battle-wound {
  padding: 0.5em 0.5em 0.5em 0.75em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #cac8c8; }

.battle-wound:last-child {
  border-bottom: 1px solid #cac8c8;
  margin-bottom: 0.5em; }

.battle-wound__name {
  font-weight: 600;
  margin-right: 0.5em; }

.battle-wound .switch {
  background: #cac8c8; }

@media only screen and (min-width: 600px) {
  .battle-wound {
    padding: 1em 0.5em 1em 1em; }
  .battle-wound:last-child {
    margin-bottom: 1em; } }

.rosette {
  background: url(./_assets/rosette.svg);
  background-size: contain;
  height: 160px;
  width: 160px;
  line-height: 160px;
  font-size: 60px;
  font-weight: bold;
  text-align: center;
  display: block;
  margin: 0 auto; }

.alert {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 101;
  transform: translateX(-50%);
  background: white;
  display: flex;
  align-items: center;
  border-left: 3em solid;
  width: 90%;
  max-width: 500px; }

.alert span {
  padding: 0.5em 1em;
  flex: 1 1 auto;
  text-align: left; }

.alert--success {
  border-left-color: #de9330; }

.alert--danger {
  border-left-color: #cc0c0c; }

.alert svg {
  flex: 0 0 3em;
  padding: 1em;
  height: 3em;
  width: 3em;
  color: #fff;
  margin-left: -3em; }

.alert .icon-check {
  padding: 0.9em; }

.alert__timer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; }

.alert__timer__inner {
  height: 3px;
  width: 50%;
  background: #de9330;
  animation: alert-timer 5s forwards linear; }

@media only screen and (min-width: 980px) {
  .alert {
    left: calc(50% + 130px); } }

@keyframes alert-timer {
  0% {
    width: 100%; }
  100% {
    width: 0%; } }

.help-text {
  padding: 1em;
  border: 1px solid #95b192;
  border-radius: 10px;
  background: #d8e2d6;
  position: relative; }

.help-text:before {
  content: 'Tips';
  border-radius: 8px;
  background: #95b192;
  position: absolute;
  bottom: calc(100% - 1em);
  left: 12px;
  font-size: 0.8em;
  padding: 0.25em 1em;
  color: #fff;
  font-weight: 600; }

.post-battle .card-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(18, 25, 33, 0.75);
  z-index: 100; }

.print {
  display: none; }

.donate-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 6;
  width: 100%;
  background: #2a323a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap; }

.donate-banner span {
  display: none;
  color: #fff;
  padding: 0 1em;
  font-weight: 600; }

.donate-banner .donate-link,
.donate-banner .donate-form {
  transition: 150ms;
  display: block;
  flex: 1 1 50%; }

.donate-banner .donate-link:hover,
.donate-banner .donate-form:hover {
  border-bottom: none !important;
  opacity: 0.9; }

.donate-banner .donate-form input {
  border: none;
  padding: 0;
  display: block; }

.donate-banner .donate-form img {
  display: none; }

.company-rules {
  page-break-before: always;
  padding: 1em 2em; }
  .company-rules .mounts table {
    table-layout: fixed; }
    .company-rules .mounts table th {
      width: 70px; }

@media screen and (min-width: 600px) {
  .donate-banner span {
    display: block;
    flex: 1 1 auto; }
  .donate-banner .donate-link,
  .donate-banner .donate-form {
    max-width: 192px; } }

@media screen and (min-width: 980px) {
  .donate-banner {
    padding-left: 260px; }
  .donate-banner span {
    text-align: right; } }

@media print {
  body {
    background: none; }
  .print {
    display: block; }
  .no-print {
    display: none; }
  #wrapper {
    margin-top: 0;
    padding: 0; }
  .donate-banner {
    display: none; } }

@page {
  margin: 0.5cm 0.25cm 0.25cm; }

.promo-container {
  display: flex;
  flex-flow: column;
  text-align: center;
  background: rgba(43, 50, 58, 0.8);
  border-radius: 15px; }

.grid .promo-container {
  padding: 10px 10px 0; }

.company-grid .promo-container {
  max-width: 350px;
  margin: 0.5em 1em; }

.promo-container > a {
  color: #de9330;
  font-size: 14px;
  text-decoration: none;
  margin: 5px 0; }

.promo-container a:hover {
  text-decoration: underline; }

#main > .promo-container {
  margin: 0 0 1em; }

.overlay .promo-container {
  margin: 0 -1em 1em;
  background: #2a323a; }

.promo {
  background: white;
  margin: auto;
  height: 250px;
  width: 300px;
  max-width: 100%;
  background: url(./_assets/logo.svg) 50% 50% no-repeat;
  background-size: 80% 80%; }

.promo--tall {
  height: 250px;
  width: 300px; }

.promo--wide {
  height: 50px;
  width: 320px; }

.promo--wide .promo-image-mobile {
  margin: 5px 0;
  border: 5px solid #262e35; }

.promo-image-mobile {
  display: block; }

.promo-image-desktop {
  display: none; }

@media screen and (min-width: 768px) {
  .warrior-grid .promo-container,
  .profile-grid .promo-container {
    margin-top: calc(1em + 28px); }
  .company-grid .promo-container {
    margin: 2em 1em 1.5em; }
  .promo--tall {
    height: 600px;
    width: 300px; }
  .promo--wide {
    height: 90px;
    width: 728px;
    margin-top: 10px; }
  .promo-image-mobile {
    display: none; }
  .promo-image-desktop {
    display: block; } }

@media screen and (min-width: 1440px) {
  #main > .promo-container {
    margin: -1em 0 1em; } }
