.btn {
  transition: color 220ms, background-color 220ms, border-color 220ms;
  border: none;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  line-height: 100%;
  font-weight: 600;
  padding: 1em;
  font-size: 1.1em;
  border: 1px solid transparent;
  position: relative;
  font-family: inherit;
  line-height: 18px;
  cursor: pointer;
}



.btn:disabled {
  background: #ccc !important;
}

.btn--block {
  display: block;
  width: 100%;
}

.btn--small {
  font-size: 14px;
  padding: 1em 2em;
}

.btn--right {
  float: right;
  margin-left: 10px;
}

.btn--primary {
  background: #de932f;
  color: #fff;
}

.btn--primary:hover {
  background: #d28621;
}

.btn--secondary {
  background: transparent;
  color: #222;
  border-color: #cac8c8;
}

.btn--secondary svg {
  transition: 220ms;
  color: #de932f;
}

.btn--secondary:hover {
  color: #fff;
  background: #de932f;
}

.btn--secondary:hover svg {
  color: #fff;
}

.btn--destructive {
  background: transparent;
  color: #222;
  border-color: #cac8c8;
  &:hover {
    color: #d64e4e;
    border-color: #d64e4e;
    background: rgba(214, 78, 78, 0.075);
  }
}

.btn--constructive {
  background: transparent;
  color: #222;
  border-color: #cac8c8;
  &:hover {
    color: #de932f;
    border-color: #de932f;
    background: rgba(#de932f, 0.075);
  }
}

.btn--icon-left svg {
  float: left;
  font-size: 1.2em;
  margin: -0.1rem 1rem -0.1rem -0.5rem;
}

.btn--icon-right svg {
  float: right;
  font-size: 1.2em;
  margin: -0.1rem -0.5rem -0.1rem 1rem;
}

@media screen and (min-width: 600px) {
  .btn {
    padding: 1em 1.5em;
  }
  .btn--small {
    padding: 1em 2em;
  }
}