.accordion {
  margin-bottom: 1px; }
  .accordion .accordion__header {
    -webkit-transition: 220ms;
    transition: 220ms;
    font-family: inherit;
    font-weight: 600;
    font-size: 18px;
    padding: 10px 48px 10px 20px;
    background: #4B565E;
    color: #fff;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    display: flex;
    justify-content: space-between;
    border: none;
    width: 100%;
    outline: none; }
    .accordion .accordion__header:hover {
      background: #5b6872; }
    .accordion .accordion__header .accordion__points {
      border-right: 1px solid rgba(255, 255, 255, 0.15);
      padding-right: 12px; }
    .accordion .accordion__header svg {
      position: absolute;
      top: 12px;
      right: 16px; }
    .accordion .accordion__header .icon-minus-thin,
    .accordion .accordion__header .icon-plus-thin {
      font-size: 26px;
      top: 6px;
      right: 12px; }
  .accordion .accordion__inner {
    display: none;
    padding: 1em;
    border: 1px solid #cac8c8;
    border-top: none;
    overflow: hidden;
    background: #F2EEE7; }
  .accordion--open .accordion__inner {
    display: block; }
  .accordion--new > .accordion__header {
    background: #F1EEE7;
    padding: 8px 48px 8px 18px;
    border: 2px dashed #4B565E;
    color: #222; }
    .accordion--new > .accordion__header:hover {
      background: #E4E2DB; }
  .accordion--new > .accordion__inner {
    border: 2px dashed #4B565E;
    border-top: none; }

#new-wargear-item {
  margin-bottom: 10px; }

.btn {
  -webkit-transition: color 220ms, background-color 220ms, border-color 220ms;
  transition: color 220ms, background-color 220ms, border-color 220ms;
  border: none;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  line-height: 100%;
  font-weight: 600;
  padding: 1em;
  font-size: 1.1em;
  border: 1px solid transparent;
  position: relative;
  font-family: inherit;
  line-height: 18px;
  cursor: pointer; }

.btn:disabled {
  background: #ccc !important; }

.btn--block {
  display: block;
  width: 100%; }

.btn--small {
  font-size: 14px;
  padding: 1em 2em; }

.btn--right {
  float: right;
  margin-left: 10px; }

.btn--primary {
  background: #de932f;
  color: #fff; }

.btn--primary:hover {
  background: #d28621; }

.btn--secondary {
  background: transparent;
  color: #222;
  border-color: #cac8c8; }

.btn--secondary svg {
  -webkit-transition: 220ms;
  transition: 220ms;
  color: #de932f; }

.btn--secondary:hover {
  color: #fff;
  background: #de932f; }

.btn--secondary:hover svg {
  color: #fff; }

.btn--destructive {
  background: transparent;
  color: #222;
  border-color: #cac8c8; }
  .btn--destructive:hover {
    color: #d64e4e;
    border-color: #d64e4e;
    background: rgba(214, 78, 78, 0.075); }

.btn--constructive {
  background: transparent;
  color: #222;
  border-color: #cac8c8; }
  .btn--constructive:hover {
    color: #de932f;
    border-color: #de932f;
    background: rgba(222, 147, 47, 0.075); }

.btn--icon-left svg {
  float: left;
  font-size: 1.2em;
  margin: -0.1rem 1rem -0.1rem -0.5rem; }

.btn--icon-right svg {
  float: right;
  font-size: 1.2em;
  margin: -0.1rem -0.5rem -0.1rem 1rem; }

@media screen and (min-width: 600px) {
  .btn {
    padding: 1em 1.5em; }
  .btn--small {
    padding: 1em 2em; } }

.number-control {
  position: relative;
  width: 105px;
  flex: 0 0 105px;
  margin: 6px 0; }

.number-control__dec, .number-control__inc {
  -webkit-transition: 220ms;
  transition: 220ms;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  padding: 0;
  background: #f1f1f1;
  border: 1px solid #cac8c8;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #4D4D4D;
  outline: none;
  margin: 0 auto; }

.number-control__dec:focus, .number-control__inc:focus {
  border-color: #de932f; }

.number-control__dec:hover, .number-control__inc:hover {
  background: #e4e2e2; }

.number-control__dec:disabled, .number-control__inc:disabled {
  background: transparent;
  color: #cac8c8; }

.number-control__dec svg, .number-control__inc svg {
  font-size: 0.75em;
  margin: 0 auto; }

.number-control__inc {
  left: auto;
  right: 0; }

.number-control__val {
  border-radius: 50%;
  text-align: center;
  margin: 2px 0;
  padding: 0 28px;
  border: none;
  font-size: 22px;
  font-weight: bold;
  height: 24px;
  line-height: 24px;
  outline: none;
  background: none; }

.number-control--read-only .number-control__val {
  padding: 0; }

input[type="radio"] {
  width: auto; }

.radio-list .radio {
  display: block; }

@media screen and (min-width: 980px) {
  .radio-list {
    display: flex;
    flex-flow: row wrap;
    flex: 1 1; }
  .radio-list .radio {
    flex: 0 1 auto;
    margin-right: 12px; }
  .radio-list .radio:last-child {
    margin-right: 0; } }

.radio {
  padding-left: 34px;
  line-height: 26px;
  overflow: hidden;
  margin: 3px 0;
  font-weight: normal; }

.radio input {
  position: absolute;
  left: -999em; }

.radio__input {
  float: left;
  margin-left: -34px;
  background: #fff;
  border: 1px solid #cac8c8;
  border-radius: 50%; }

.radio:hover .radio__input {
  border-color: #aaa; }

.radio__input:before {
  content: '';
  -webkit-transition: 220ms;
  transition: 220ms;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  display: block;
  height: 16px;
  width: 16px;
  margin: 5px;
  background: #de932f;
  border-radius: 50%;
  opacity: 0; }

.radio input:checked ~ .radio__input:before {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1; }

.file-upload {
  flex: 1 1 100%;
  border: 1px solid #ccc;
  overflow: hidden; }

.file-upload input {
  position: absolute;
  left: -999em;
  visibility: hidden; }

.file-upload .btn {
  padding: 10px 20px;
  margin: -1px 0;
  font-size: 1em;
  float: left;
  width: 50%;
  line-height: 14.5px; }

.file-upload .btn:first-child {
  width: 100%; }

.file-upload .btn .loader {
  height: 25px; }

.file-upload .btn .icon-arrow-right {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  padding-left: 2px;
  border-left: 0.075em solid white;
  height: 15px;
  margin-top: 1px;
  margin-left: 8px; }

.file-upload .btn--secondary {
  border: none;
  padding: 11px 20px; }

.file-upload .btn--secondary:hover {
  background: none;
  color: #de932f; }

.file-upload .file-upload__change {
  flex: 0 0 auto; }

.file-upload .file-upload__name {
  clear: both;
  font-weight: 600;
  padding: 10px;
  display: block; }

.file-upload .file-upload__name--error {
  color: #ca1b1b; }

@media screen and (min-width: 600px) {
  .file-upload {
    display: flex;
    align-items: center; }
  .file-upload .btn,
  .file-upload .btn:first-child {
    width: auto; }
  .file-upload .file-upload__name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1 1;
    padding: 0 10px; } }

@media screen and (min-width: 960px) {
  .file-upload {
    max-width: calc(100% - 115px); } }

.form__container {
  display: flex;
  flex-flow: column;
  height: 100%; }

.form__container .form__inner {
  flex: 1 1 auto;
  padding: 1em;
  overflow: auto; }

@media only screen and (min-width: 600px) {
  .form__container .form__inner {
    padding: 1.5em; } }

@media only screen and (min-width: 1440px) {
  .form__container .form__inner {
    padding: 2em; } }

.form__container .form__footer {
  flex: 0 0 55px;
  background: rgba(0, 0, 0, 0.075); }
  .form__container .form__footer .btn {
    width: 50%; }
    #edit-company .form__container .form__footer .btn {
      width: 33.333%; }

@media only screen and (min-width: 600px) {
  .form__container .form__footer .btn {
    width: auto; }
    #edit-company .form__container .form__footer .btn {
      width: auto; } }

.form__container .form__footer .btn--primary {
  float: right; }

.form__container .form__footer .btn--destructive,
.form__container .form__footer .btn--constructive,
.form__container .form__footer .btn--secondary {
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent; }

.row:last-child .field {
  margin-bottom: 0; }

.row--modify-characteristics {
  overflow: hidden; }

.row--modify-characteristics .field {
  float: left;
  width: 60%; }

.row--modify-characteristics .field:first-child {
  padding-right: 10px;
  width: 40%; }

.row--modify-casting-value {
  overflow: hidden; }

.row--modify-casting-value .field {
  float: left;
  width: 50%; }

.row--modify-casting-value .field:first-child {
  padding-right: 10px; }

@media screen and (min-width: 600px) {
  .row--modify-casting-value {
    max-width: 300px; } }

@media screen and (min-width: 980px) {
  .row--modify-characteristics .field:last-of-type label {
    display: none; }
  #result-overlay .row--modify-characteristics .field {
    width: 42%; }
  #result-overlay .row--modify-characteristics .field:first-child {
    width: 58%; }
  .row--modify-casting-value {
    max-width: 480px; }
  .row--modify-casting-value .field:last-of-type {
    width: calc(50% - 115px); }
  .row--modify-casting-value .field:last-of-type label {
    display: none; }
  .card-overlay .row--modify-casting-value .field {
    width: 50%; }
  .card-overlay .row--modify-casting-value .field:first-child {
    width: 50%; }
  .card-overlay .row--modify-casting-value .field:last-of-type label {
    display: block; } }

.row--points {
  display: flex;
  align-items: center; }

.row--points > span {
  margin: 1em 0.5em 0.5em;
  flex: 0 1; }

.row--points .field {
  flex: 0 1; }

@media screen and (min-width: 980px) {
  .row--points > span {
    margin: 0 0.5em 1em; }
  .row--points .field:first-of-type {
    flex: 0 0 170px; }
  .row--points .field:nth-of-type(2) label {
    display: none; }
  .row--base-stats {
    overflow: hidden; }
  .row--base-stats .field {
    float: left;
    width: calc(100% - 235px); }
  .row--base-stats .field:last-child {
    padding-left: 20px;
    width: 235px; } }

.form__inner-footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center; }

.row--cb-save {
  flex: 1 1 100%;
  text-align: right; }

.row--cb-save .checkbox {
  padding: 0 34px 0 0; }

.row--cb-save .checkbox .checkbox__input {
  float: right;
  margin: 0 -34px 0 0; }

@media screen and (min-width: 600px) {
  .row--cb-save {
    flex: initial;
    text-align: right; }
  .row--cb-save .field {
    margin-bottom: 0; } }

.field {
  margin-bottom: 1em; }
  .field--border {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    border-bottom: 1px solid #cac8c8;
    padding: 2px 10px; }
    .field--border:last-child {
      border-bottom: none; }
    .field--border label {
      flex: 1 0 auto; }

.row--table table {
  min-width: 0;
  flex: 1 1; }

#result-overlay .row--table table {
  flex: 1 1 100%;
  margin-top: 5px; }

@media screen and (min-width: 980px) {
  .field {
    display: flex;
    align-items: center;
    flex-flow: row wrap; }
  input, textarea, select {
    flex: 1 1;
    max-width: 100%; }
  .row--table .field label {
    align-self: flex-start;
    padding-top: 4px; } }

label {
  font-size: 16px;
  font-weight: 600;
  display: block;
  padding-bottom: 5px; }

@media screen and (min-width: 980px) {
  label {
    flex: 0 0 115px;
    padding-bottom: 0;
    text-overflow: ellipsis;
    overflow-x: hidden; } }

input,
textarea {
  font: inherit;
  width: 100%;
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #cac8c8;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0; }

input.small {
  width: 50px;
  max-width: 50px;
  text-align: center; }

#root select {
  display: block;
  font: inherit;
  font-size: 16px;
  line-height: 18px;
  padding: 8px 36px 8px 12px;
  width: 100%;
  max-width: 100%;
  border: 1px solid #cac8c8;
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNi4yIDE2LjIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDE2LjIgMTYuMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTguMSwxMi41Yy0wLjMsMC0wLjUtMC4xLTAuNy0wLjNMMC41LDUuNGMtMC40LTAuNC0wLjQtMSwwLTEuNHMxLTAuNCwxLjQsMGw2LjIsNi4ybDYuMi02LjJjMC40LTAuNCwxLTAuNCwxLjQsMA0KCQlzMC40LDEsMCwxLjRsLTYuOSw2LjlDOC42LDEyLjQsOC40LDEyLjUsOC4xLDEyLjV6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==");
  background-repeat: no-repeat;
  background-position: right 12px top 50%, 0 0;
  background-size: 16px; }

#root select[multiple] {
  background-image: none; }

select::-ms-expand {
  display: none; }

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000; }

input:hover,
textarea:hover,
#root select:hover {
  border-color: #aaa; }

input:focus,
textarea:focus,
select:focus {
  border-color: #de932f;
  outline: none; }

option:disabled {
  padding: 0;
  line-height: 1px;
  display: block; }

input[type="checkbox"] {
  width: auto; }

textarea {
  height: 5em; }
  textarea.large {
    height: 10em; }

.checkbox-list .checkbox {
  display: block; }

@media screen and (min-width: 980px) {
  .checkbox-list {
    display: flex;
    flex-flow: row wrap;
    flex: 1 1; }
  .checkbox-list .checkbox {
    flex: 0 1 auto;
    margin-right: 16px; }
  .checkbox-list .checkbox:last-child {
    margin-right: 0; } }

@media screen and (min-width: 1200px) {
  .checkbox-list .checkbox {
    margin-right: 25px; } }

.checkbox {
  padding-left: 34px;
  line-height: 26px;
  overflow: hidden;
  margin: 3px 0;
  font-weight: normal;
  flex: 1 1 auto; }

.checkbox input {
  position: absolute;
  left: -999em; }

.checkbox__input {
  float: left;
  margin-left: -34px;
  background: #fff;
  border: 1px solid #cac8c8; }

.checkbox:hover .checkbox__input {
  border-color: #aaa; }

.checkbox__input:before {
  content: '';
  -webkit-transition: 220ms;
  transition: 220ms;
  -webkit-transform: scale(0.8) rotate(45deg);
          transform: scale(0.8) rotate(45deg);
  display: block;
  height: 18px;
  width: 10px;
  margin: 3px 8px 5px;
  border-bottom: 4px solid #de932f;
  border-right: 4px solid #de932f;
  opacity: 0; }

.checkbox input:checked ~ .checkbox__input:before {
  -webkit-transform: scale(1) rotate(45deg);
          transform: scale(1) rotate(45deg);
  opacity: 1; }

.field-validation-error {
  flex: 1 1 100%;
  padding: 2px 0;
  color: #a53939;
  font-size: 14px; }

@media screen and (min-width: 980px) {
  .field .field-validation-error {
    padding-left: 115px; } }

.text-page-form {
  padding-bottom: 3em; }

.text-page-forms > .text-page-form {
  padding: 1.5em;
  margin-bottom: 2em;
  background: rgba(34, 40, 48, 0.8);
  border-radius: 10px; }

.text-page-forms .text-page-form h3 {
  margin: 0 0 0.5em;
  font-weight: 300;
  font-size: 26px;
  color: #fff; }

.text-page-form > span {
  font-weight: 600;
  padding: 3em 0 2em;
  text-align: center;
  display: block; }

.text-page-form .form__inner {
  overflow: visible;
  padding: 0; }

.text-page-form .form__inner input {
  font-size: 1.1em;
  padding: 12px 15px; }

.text-page-form .field-validation-error {
  padding-left: 0;
  color: #ec5d5d; }

.text-page-form .form__inner .row:last-child .field-validation-error {
  margin-bottom: -26px;
  float: left; }

.text-page-form .form__footer .btn {
  padding: 14px 18px;
  margin-top: -47px;
  width: 132px; }

.text-page-form .form__inner .row:last-child {
  width: calc(100% - 144px); }

@media screen and (min-width: 1200px) {
  .text-page-forms {
    display: flex;
    justify-content: space-between; }
  .text-page-forms .text-page-form h3 {
    font-size: 32px; }
  .text-page-forms > .text-page-form {
    flex: 1 1 40%;
    max-width: calc(50% - 1em);
    padding: 2em;
    border-radius: 20px; }
  .text-page-forms > span {
    flex: 0 1 20%;
    display: flex;
    justify-content: center;
    align-items: center; } }

.switch {
  display: flex;
  background: #2b323a;
  border-radius: 20px;
  position: relative;
  min-width: 150px;
  padding-bottom: 0; }

@media only screen and (min-width: 980px) {
  .switch {
    min-width: 170px; } }

.switch input {
  position: absolute;
  left: -999em; }

.switch__value {
  -webkit-transition: 220ms;
  transition: 220ms;
  position: relative;
  z-index: 2;
  width: 50%;
  padding: 6px;
  text-align: center;
  color: #5b6a72; }

.switch__value:first-of-type {
  color: #fff; }

.switch__inner {
  -webkit-transition: 220ms;
  transition: 220ms;
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 1;
  display: block;
  width: calc(50% - 3px);
  height: calc(100% - 6px);
  border-radius: 20px;
  padding: 3px;
  background: #9B9792; }

.switch input:checked ~ .switch__inner {
  background: #fff;
  left: 50%; }

.switch input:checked ~ .switch__value {
  color: #2b323a; }

.switch input:checked ~ .switch__value:first-of-type {
  color: #5b6a72; }

.card__rules {
  margin-bottom: 1em;
  font-size: 12px;
  flex: auto;
  display: flex;
  flex-flow: column; }

.card__rules__header {
  flex: 0 0 26px;
  align-self: flex-start; }

.card__rules__inner {
  padding: 0.5em 1em;
  flex: auto; }

.card__rules--wargear .card__rules__header {
  background: #af9895; }

.card__rules--wargear .card__rules__inner {
  background: #e8e0df; }

.card__rules--special-rules .card__rules__header {
  background: #95b193; }

.card__rules--special-rules .card__rules__inner {
  background: #d8e2d7; }

.card__rules--attacks .card__rules__header {
  background: #959daf; }

.card__rules--attacks .card__rules__inner {
  background: #dfe1e8; }

.card__rules--heroic-actions .card__rules__header {
  background: #d3c07c; }

.card__rules--heroic-actions .card__rules__inner {
  background: #f0ecd9; }

.card__rules--spells .card__rules__header {
  background: #959daf; }

.card__rules--spells .card__rules__inner {
  background: #dfe1e8; }

.card__rules__item {
  font-family: inherit;
  background: none;
  border: none;
  padding: 0;
  cursor: inherit; }

.card__rules--heroic-actions .card__rules__item {
  display: block;
  white-space: nowrap; }

.card__rules--heroic-actions .card__rules__item::after {
  display: none; }

.card__rules--spells .card__rules__item {
  display: block;
  white-space: nowrap; }

.card__rules--spells .card__rules__item::after {
  display: none; }

.card__rules__item--clickable:hover {
  text-decoration: underline;
  cursor: pointer; }

.card__rules__item::after {
  content: ',';
  margin-right: 3px; }

.card__rules__item:last-child::after {
  display: none; }

@media print {
  .card__rules__header {
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: -1px;
    position: relative;
    z-index: 2; }
  .card__rules__inner {
    border: 1px solid rgba(0, 0, 0, 0.2); } }

.card {
  -webkit-transition: 220ms;
  transition: 220ms;
  max-width: 420px;
  -webkit-perspective: 2000px;
          perspective: 2000px;
  position: relative;
  background: none;
  border: none; }

.blood {
  position: absolute;
  opacity: 0.6;
  width: 75%;
  z-index: 3; }

.card--overlay {
  visibility: hidden;
  -webkit-perspective: none;
          perspective: none;
  -webkit-perspective: initial;
          perspective: initial;
  z-index: 20; }

.card--overlay:first-child {
  visibility: visible; }

.card--overlay:first-child .card__inner {
  -webkit-animation: overlay-card-in 400ms forwards;
          animation: overlay-card-in 400ms forwards;
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%; }

.card--overlay.card--animate-out:first-child .card__inner {
  -webkit-animation: overlay-card-out 400ms forwards;
          animation: overlay-card-out 400ms forwards;
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%; }

@-webkit-keyframes overlay-card-in {
  0% {
    -webkit-transform: translateX(50%) translateY(-20%) rotate(10deg);
            transform: translateX(50%) translateY(-20%) rotate(10deg);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(0deg);
            transform: translateX(-50%) translateY(-50%) rotate(0deg);
    opacity: 1; } }

@keyframes overlay-card-in {
  0% {
    -webkit-transform: translateX(50%) translateY(-20%) rotate(10deg);
            transform: translateX(50%) translateY(-20%) rotate(10deg);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(0deg);
            transform: translateX(-50%) translateY(-50%) rotate(0deg);
    opacity: 1; } }

@-webkit-keyframes overlay-card-out {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(0deg);
            transform: translateX(-50%) translateY(-50%) rotate(0deg);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(-150%) translateY(-20%) rotate(-10deg);
            transform: translateX(-150%) translateY(-20%) rotate(-10deg);
    opacity: 0; } }

@keyframes overlay-card-out {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(0deg);
            transform: translateX(-50%) translateY(-50%) rotate(0deg);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(-150%) translateY(-20%) rotate(-10deg);
            transform: translateX(-150%) translateY(-20%) rotate(-10deg);
    opacity: 0; } }

.card--overlay .card__inner {
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  position: fixed;
  max-width: 95vw;
  width: 420px;
  height: 700px;
  max-height: 100%;
  top: 50%;
  left: 50%;
  z-index: 2;
  overflow: auto;
  padding: 10px 0; }

.card--clickable {
  cursor: pointer; }

.card--clickable:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02); }

.card--clickable:hover .card__front {
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.4); }

.card--highlight {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

.card--flip {
  -webkit-animation: flip-parent 750ms forwards;
  animation: flip-parent 750ms forwards; }

.card--flip .card__inner {
  -webkit-animation: flip 750ms forwards cubic-bezier(0.175, 0.885, 0.32, 1.2);
  animation: flip 750ms forwards cubic-bezier(0.175, 0.885, 0.32, 1.2); }

.card--flip .card__rank {
  color: transparent; }

.card--flip .card__back {
  z-index: 3; }

.card--flip:hover {
  -webkit-animation: unflip-parent 750ms forwards;
  animation: unflip-parent 750ms forwards;
  z-index: 10; }

.card--flip:hover .card__inner {
  -webkit-animation: unflip 750ms forwards cubic-bezier(0.175, 0.885, 0.32, 1.2);
  animation: unflip 750ms forwards cubic-bezier(0.175, 0.885, 0.32, 1.2); }

.card--flip:hover .card__rank {
  color: #fff; }

.card__inner {
  position: relative;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  height: 100%;
  display: flex;
  flex-flow: column; }

.card__front {
  -webkit-transition: 220ms;
  transition: 220ms;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  min-height: 600px;
  border-radius: 15px;
  background: #fff;
  padding: 0.5em 1em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
  z-index: 2;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2); }

.card--wargear .card__front {
  min-height: 200px; }

.card--wargear table {
  margin-top: 5px; }

.card--rule .card__front {
  min-height: 200px; }

.card__back {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  top: 28px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: #2b323a;
  border-radius: 15px;
  border: 15px solid #fff;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 10% 0; }

.card__back:before {
  content: '';
  position: absolute;
  top: 8px;
  left: 8px;
  bottom: 8px;
  right: 8px;
  border: 3px solid #5F5245; }

.card__back:after {
  content: '';
  position: absolute;
  top: 14px;
  left: 14px;
  bottom: 14px;
  right: 14px;
  border: 3px solid #5F5245;
  background: url(/static/media/card-bg.5e6984ab.svg) 50% 50% no-repeat;
  background-size: contain; }

.card__back span {
  text-align: center;
  font-size: 24px;
  color: #927965;
  display: block;
  position: relative;
  z-index: 2; }

.card__bottom .injuries {
  display: none; }

.card__back span:first-child {
  font-size: 32px; }

.card__back .blood {
  top: 30%;
  left: 20px; }

.card__rank {
  flex: 0 0 28px;
  align-self: flex-start;
  margin-left: 1.5em;
  font-weight: 600;
  font-size: 0.8em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 280px;
  background: #425562 -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0.2)), to(rgba(0, 0, 0, 0.3)));
  background: #425562 -webkit-linear-gradient(right, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.3));
  background: #425562 linear-gradient(to left, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.3)); }

.card__rank--warrior {
  visibility: hidden; }

.card__rank--leader {
  background: #ffb000 -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0.2)), to(rgba(0, 0, 0, 0.3)));
  background: #ffb000 -webkit-linear-gradient(right, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.3));
  background: #ffb000 linear-gradient(to left, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.3)); }

.card__rank--sergeant {
  background: #cac0cc -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0.2)), to(rgba(0, 0, 0, 0.3)));
  background: #cac0cc -webkit-linear-gradient(right, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.3));
  background: #cac0cc linear-gradient(to left, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.3)); }

.card__rank--hero {
  background: #9b6a54 -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0.2)), to(rgba(0, 0, 0, 0.3)));
  background: #9b6a54 -webkit-linear-gradient(right, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.3));
  background: #9b6a54 linear-gradient(to left, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.3)); }

.card__header {
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  margin-bottom: 0.25em;
  overflow: hidden; }

.card__header__name {
  float: left;
  width: calc(100% - 60px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.card__header__points {
  float: right;
  width: 55px;
  text-align: right; }

.card__header__points small {
  font-size: 0.7em;
  color: #9B9792;
  font-weight: normal; }

.card__image {
  position: relative;
  height: 192px;
  background-position: 50% 50%;
  background-color: #eee;
  background-size: cover; }

.card__divider {
  border-bottom: 2px solid #eee;
  margin-bottom: 10px; }

.card__image .blood {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  top: -30px;
  right: -10px; }

.card__image .injuries {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 2;
  overflow: hidden; }

.card__image .injury {
  float: left;
  margin-right: 10px;
  border-radius: 50%;
  color: #fff;
  background: rgba(120, 0, 0, 0.8);
  border: 2px solid rgba(0, 0, 0, 0.2);
  padding: 0.2em;
  font-size: 2em; }

.card__image .injury svg {
  display: block; }

.card__image .btn--primary {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  padding: 0.5em;
  font-size: 15px; }

.card__image .btn--primary svg {
  margin: 0 0 0 0.25em; }

.card__details {
  font-size: 0.7em;
  display: flex;
  justify-content: space-between;
  padding: 0.5em 0; }

.card__details__class {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 1 50%; }

.card__details__path {
  flex: 1 1 40%;
  text-align: right; }

.card .table--stats {
  width: 100%;
  min-width: 0; }

.card .table--stats thead tr th {
  padding: 0.25em 0.5em; }

.card .table--stats tbody tr td {
  padding: 0.25em 0.5em; }

.card .table .number-control {
  flex: 0 0 100%;
  width: 100%; }

.card__rules__container {
  display: flex;
  flex: auto; }

.card__rules__container__left {
  display: flex;
  flex: auto;
  flex-flow: column; }

.card__rules__container__right {
  display: flex;
  flex: 0 0 auto;
  margin-left: 1em;
  flex-flow: column; }

.card__bottom {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #E6E6E6;
  margin: 0.5em -1em 0;
  padding: 0.5em 1em 0; }

.card .rosette {
  margin-top: 35px; }

.card .grow-in {
  -webkit-animation: grow-in 500ms forwards;
          animation: grow-in 500ms forwards; }

.card .grow-out {
  -webkit-animation: grow-out 500ms forwards;
          animation: grow-out 500ms forwards; }

.card .shrink-in {
  -webkit-animation: shrink-in 500ms forwards;
          animation: shrink-in 500ms forwards; }

.card .shrink-out {
  -webkit-animation: shrink-out 500ms forwards;
          animation: shrink-out 500ms forwards; }

.card .form__container {
  flex: 1 1;
  margin-bottom: 1em;
  background: #F7F4ED;
  overflow: hidden; }

.card .form__container:last-child {
  margin-bottom: 0.5em;
  border-radius: 0 0 10px 10px; }

.card .form__container .form__inner {
  padding: 1em; }

.card .form__container h3 {
  margin: -1rem -1rem 1rem -1rem;
  padding: 1rem;
  background: rgba(220, 205, 178, 0.3); }

.card .form__container .field {
  display: block; }

.card .form__container .radio-list {
  display: block; }

.card .form__container .radio-list .radio {
  margin: 8px 0; }

.card .form__container .field .field-validation-error {
  padding-left: 0; }

.card__experience {
  margin-bottom: 5px; }

.card__experience__level {
  float: left;
  font-size: 22px;
  text-align: center;
  font-weight: bold;
  line-height: 34px;
  width: 40px;
  height: 40px;
  background: url(/static/media/experience.7dcdfa86.svg) 50% 50% no-repeat;
  background-size: 100%; }

.card__experience .experience-bar-title {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  display: block;
  margin-left: 42px; }

.card__experience .experience-bar {
  background: #f1f1f1;
  height: 15px;
  margin-left: 20px; }

.card__experience .experience-bar__inner {
  -webkit-transition: width 220ms;
  transition: width 220ms;
  height: 15px;
  width: 0;
  background: -webkit-gradient(linear, left top, right top, from(#734200), to(#FBC300));
  background: -webkit-linear-gradient(left, #734200, #FBC300);
  background: linear-gradient(to right, #734200, #FBC300); }

.card__experience .experience-bar--1 .experience-bar__inner {
  width: 20%; }

.card__experience .experience-bar--2 .experience-bar__inner {
  width: 40%; }

.card__experience .experience-bar--3 .experience-bar__inner {
  width: 60%; }

.card__experience .experience-bar--4 .experience-bar__inner {
  width: 80%; }

.card__experience .experience-bar--5 .experience-bar__inner {
  width: 100%; }

.card__counters {
  flex: 1 1;
  display: flex;
  justify-content: space-between; }

.card-counter {
  position: relative;
  flex: 0 0 122px;
  max-width: 122px;
  align-items: center; }

.card-counter__controls {
  display: flex;
  float: left;
  margin: 1px 0; }

.card-counter__dec, .card-counter__inc {
  -webkit-transition: 220ms;
  transition: 220ms;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background: #f1f1f1;
  border: 1px solid #E6E6E6;
  cursor: pointer;
  padding: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  color: #4D4D4D; }

.card-counter__dec:hover, .card-counter__inc:hover {
  background: #e4e2e2; }

.card-counter__dec:disabled, .card-counter__inc:disabled {
  background: transparent;
  color: #cac8c8;
  cursor: default; }

.card-counter__dec:disabled:hover, .card-counter__inc:disabled:hover {
  background: transparent; }

.card-counter__dec svg, .card-counter__inc svg {
  font-size: 0.75em;
  margin: 0 auto; }

.card-counter__inc {
  border-radius: 0 50% 50% 0; }

.card-counter__dec {
  border-radius: 50% 0 0 50%;
  margin-right: -1px; }

.card-counter > svg {
  font-size: 26px;
  margin-top: 4px;
  color: #A0836E;
  float: left; }

.card-counter__val {
  font-size: 34px;
  font-weight: bold;
  min-width: 30px;
  text-align: center;
  margin-right: 3px;
  float: left;
  line-height: 30px; }

.card-counter__title {
  font-size: 13px;
  line-height: 14px;
  font-weight: 600;
  float: left;
  clear: both;
  width: 100%;
  text-align: center;
  margin-bottom: 3px; }

.marker-circles {
  display: none;
  line-height: 15px; }

.card__bottom .marker-circles {
  max-width: 60px;
  margin: 0 5px; }

.flee-container .btn {
  padding: 1px 5px;
  font-size: 12px;
  display: flex;
  flex-flow: column;
  align-items: center;
  border-radius: 10px;
  background: #f1f1f1;
  border: 1px solid #ddd; }

@media screen and (min-width: 600px) {
  .flee-container .btn {
    padding: 1px 10px; } }

.flee-container .btn:hover {
  background: #de932f;
  border-color: #de932f;
  color: #fff; }

.flee-container .btn svg {
  color: currentColor;
  font-size: 2em;
  margin: 0 0 0.1em 0; }

.marker-circle {
  display: inline-block;
  border: 1px solid #aaa;
  border-radius: 10px;
  height: 10px;
  width: 10px;
  margin: 0 2px 0; }

.marker-circles__experience {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  width: 315px; }

.marker-circles__experience .marker-circle {
  width: 8px;
  height: 8px;
  margin: 0 1px 0 0; }

.marker-circles__experience .marker-circle--filled {
  border-width: 4px;
  border-color: #bbb; }

.marker-circles__experience .marker-circle:nth-of-type(5n) {
  border-color: #A0836E; }

.injury-status {
  position: absolute;
  top: 0;
  right: 0;
  color: #af1012;
  padding: 0 1em;
  font-weight: 600; }

@-webkit-keyframes flip-parent {
  0% {
    z-index: 1; }
  1% {
    z-index: 2; }
  99% {
    z-index: 2; }
  100% {
    z-index: 1; } }

@keyframes flip-parent {
  0% {
    z-index: 1; }
  1% {
    z-index: 2; }
  99% {
    z-index: 2; }
  100% {
    z-index: 1; } }

@-webkit-keyframes unflip-parent {
  0% {
    z-index: 1; }
  1% {
    z-index: 2; }
  99% {
    z-index: 2; }
  100% {
    z-index: 1; } }

@keyframes unflip-parent {
  0% {
    z-index: 1; }
  1% {
    z-index: 2; }
  99% {
    z-index: 2; }
  100% {
    z-index: 1; } }

@-webkit-keyframes flip {
  0% {
    -webkit-transform: rotateY(0deg) scale(1) translateX(0) translateY(0);
            transform: rotateY(0deg) scale(1) translateX(0) translateY(0); }
  70% {
    -webkit-transform: rotateY(180deg) scale(1.1) translateX(20px) translateY(10px);
            transform: rotateY(180deg) scale(1.1) translateX(20px) translateY(10px); }
  100% {
    -webkit-transform: rotateY(180deg) scale(1) translateX(0) translateY(0);
            transform: rotateY(180deg) scale(1) translateX(0) translateY(0); } }

@keyframes flip {
  0% {
    -webkit-transform: rotateY(0deg) scale(1) translateX(0) translateY(0);
            transform: rotateY(0deg) scale(1) translateX(0) translateY(0); }
  70% {
    -webkit-transform: rotateY(180deg) scale(1.1) translateX(20px) translateY(10px);
            transform: rotateY(180deg) scale(1.1) translateX(20px) translateY(10px); }
  100% {
    -webkit-transform: rotateY(180deg) scale(1) translateX(0) translateY(0);
            transform: rotateY(180deg) scale(1) translateX(0) translateY(0); } }

@-webkit-keyframes unflip {
  0% {
    -webkit-transform: rotateY(180deg) scale(1) translateX(0) translateY(0);
            transform: rotateY(180deg) scale(1) translateX(0) translateY(0); }
  70% {
    -webkit-transform: rotateY(0deg) scale(1.1) translateX(-20px) translateY(10px);
            transform: rotateY(0deg) scale(1.1) translateX(-20px) translateY(10px); }
  100% {
    -webkit-transform: rotateY(0deg) scale(1) translateX(0) translateY(0);
            transform: rotateY(0deg) scale(1) translateX(0) translateY(0); } }

@keyframes unflip {
  0% {
    -webkit-transform: rotateY(180deg) scale(1) translateX(0) translateY(0);
            transform: rotateY(180deg) scale(1) translateX(0) translateY(0); }
  70% {
    -webkit-transform: rotateY(0deg) scale(1.1) translateX(-20px) translateY(10px);
            transform: rotateY(0deg) scale(1.1) translateX(-20px) translateY(10px); }
  100% {
    -webkit-transform: rotateY(0deg) scale(1) translateX(0) translateY(0);
            transform: rotateY(0deg) scale(1) translateX(0) translateY(0); } }

@-webkit-keyframes grow-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.8);
            transform: scale(0.8); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes grow-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.8);
            transform: scale(0.8); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes grow-out {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.8);
            transform: scale(0.8); } }

@keyframes grow-out {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.8);
            transform: scale(0.8); } }

@-webkit-keyframes shrink-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes shrink-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes shrink-out {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.2);
            transform: scale(1.2); } }

@keyframes shrink-out {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.2);
            transform: scale(1.2); } }

@media print {
  .card {
    -webkit-transform: none !important;
            transform: none !important;
    height: calc(50vh - 8em);
    min-height: 500px;
    margin: 0.5em; }
  .card--flip,
  .card--flip:hover,
  .card--flip .card__inner,
  .card--flip:hover .card__inner {
    -webkit-animation: none;
    animation: none; }
  .card--flip .card__rank {
    color: #fff; }
  .card:nth-child(4n+1) {
    page-break-before: always; }
  .card__rank {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: none; }
  .card__front {
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    min-height: 0;
    height: 100%; }
  .card__header {
    margin-bottom: 0;
    flex: 0 0 auto; }
  .card__header .injuries {
    display: inline-block; }
  .card__header .injury {
    float: left;
    margin-left: 5px;
    color: #c11111; }
  .card__header .injury svg {
    display: block; }
  .card__details {
    padding-top: 0; }
  .card__image {
    display: none; }
  .card table,
  .card .card__rules {
    margin-bottom: 0.5em; }
  .card__back {
    display: none; }
  .card__experience__level {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    background: #fff;
    position: relative;
    z-index: 20; }
  .card__experience .experience-bar {
    border: 1px solid rgba(0, 0, 0, 0.2); }
  .card__experience .experience-bar__inner {
    border: 1px solid rgba(0, 0, 0, 0.4);
    margin: -1px 0; }
  .card .marker-circles {
    display: block;
    max-width: none; }
  .card .card__bottom .card-counter {
    text-align: center; }
  .card .card__bottom .card-counter svg {
    margin-right: 5px;
    margin-top: 0;
    float: none;
    display: inline-block;
    vertical-align: middle; }
  .card .card__bottom .marker-circles {
    min-height: 32px;
    display: inline-flex;
    flex-flow: row wrap;
    align-items: center;
    width: auto;
    max-width: 80px;
    vertical-align: middle; }
  .card-counter__title {
    float: none;
    display: block; }
  .card-counter__val {
    visibility: hidden; }
  .card-counter__controls {
    display: none; }
  .card .number-control {
    display: none; } }

.deck {
  -webkit-transition: 220ms;
  transition: 220ms;
  position: relative;
  height: 100%;
  max-width: 350px;
  text-decoration: none;
  display: block;
  background: none;
  font-family: inherit;
  border: none;
  padding: 0; }

.deck__outer {
  padding: 1em; }

.deck__container {
  height: 100%; }

.deck__inner {
  -webkit-transform-origin: 15px calc(100% - 15px);
          transform-origin: 15px calc(100% - 15px);
  -webkit-transition: 220ms;
  transition: 220ms;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 15px;
  z-index: 5;
  background: #2b323a;
  border: 15px solid #fff;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 30px;
  color: #bf7d26;
  min-height: 500px;
  height: 100%; }

.deck__inner:before {
  content: '';
  position: absolute;
  top: 8px;
  left: 8px;
  bottom: 8px;
  right: 8px;
  border: 3px solid #5F5245; }

.deck__inner:after {
  content: '';
  position: absolute;
  top: 14px;
  left: 14px;
  bottom: 14px;
  right: 14px;
  border: 3px solid #5F5245; }

.deck__inner img {
  height: 250px;
  margin: 5px;
  align-self: center; }

.deck__name {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  display: block; }

.deck__influence, .deck__rating, .deck__warrior-count {
  display: flex;
  align-items: center;
  justify-content: center; }

.deck__influence span, .deck__rating span, .deck__warrior-count span {
  width: 60%;
  text-align: right; }

.deck__influence strong, .deck__rating strong, .deck__warrior-count strong {
  color: #fff;
  font-size: 24px;
  vertical-align: middle;
  margin-left: 10px;
  text-align: left;
  width: 40%; }

.deck__cards {
  -webkit-transition: 220ms;
  transition: 220ms;
  -webkit-transform-origin: 15px calc(100% - 15px);
          transform-origin: 15px calc(100% - 15px);
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 6px;
  left: -6px;
  z-index: 1;
  border-radius: 15px;
  background: #fff; }

.deck__cards:before, .deck__cards:after {
  content: '';
  -webkit-transition: 220ms;
  transition: 220ms;
  -webkit-transform-origin: 15px calc(100% - 15px);
          transform-origin: 15px calc(100% - 15px);
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  margin: -4px 0 0 4px;
  border-radius: 15px;
  background: #fff; }

.deck__cards:after {
  margin: -2px 0 0 2px;
  z-index: 2; }

a.deck:hover .deck__inner {
  -webkit-transform: rotate(4deg);
          transform: rotate(4deg); }

a.deck:hover .deck__cards {
  -webkit-transform: rotate(-2deg);
          transform: rotate(-2deg); }

a.deck:hover .deck__cards:before {
  -webkit-transform: rotate(4deg);
          transform: rotate(4deg); }

a.deck:hover .deck__cards:after {
  -webkit-transform: rotate(2deg);
          transform: rotate(2deg); }

.grid {
  display: flex;
  flex-flow: row wrap;
  display: grid;
  grid-template-columns: 1fr;
  margin: -0.5em -0.5em 2em; }

.grid > * {
  flex: 1 1 100%;
  margin: 0.5em; }

@media only screen and (min-width: 720px) {
  .grid {
    grid-template-columns: repeat(2, 1fr); }
  .grid > * {
    flex: 1 1 40%; } }

@media only screen and (min-width: 1366px) {
  .grid {
    grid-template-columns: repeat(3, 1fr); }
  .grid > * {
    flex: 1 1 26%; } }

@media only screen and (min-width: 1440px) {
  .grid {
    margin: -1em -1em 2em; }
  .grid > * {
    margin: 1em; } }

@media only screen and (min-width: 1800px) {
  .grid {
    grid-template-columns: repeat(4, 1fr); }
  .grid > * {
    flex: 1 1 21%; } }

@media print {
  .grid {
    display: block;
    text-align: center;
    min-width: 735px; }
  .grid > * {
    display: inline-block;
    vertical-align: top;
    width: 350px;
    text-align: left; } }

.header {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 40px; }

.header__top__inner {
  -webkit-transition: 220ms;
  transition: 220ms;
  text-align: center;
  background: #2b323a;
  padding: 12px 20px;
  color: #bf7d26;
  border: none;
  display: block;
  width: 100%;
  line-height: 120%;
  font-family: inherit; }

.header__sort,
.header__buttons {
  display: flex; }
  .header__sort .btn,
  .header__buttons .btn {
    padding: 0.5em;
    font-size: 0.75em;
    display: flex;
    flex-flow: column;
    align-items: center;
    flex: 1 0 60px;
    min-width: 60px;
    margin-left: 1px; }
    .header__sort .btn--primary,
    .header__buttons .btn--primary {
      flex: 1 0 100px;
      min-width: 100px; }
    .header__sort .btn--secondary,
    .header__buttons .btn--secondary {
      color: #c2c5c7;
      background: #3b424c;
      border-color: transparent; }
      .header__sort .btn--secondary:hover,
      .header__buttons .btn--secondary:hover {
        background: #4d545f;
        color: #fff; }
    .header__sort .btn svg,
    .header__buttons .btn svg {
      -webkit-transition: 0ms;
      transition: 0ms;
      color: currentColor;
      font-size: 2em;
      margin: 0.3em 0 0.2em;
      order: -1; }

button.header:hover {
  background: #39414a; }

.header--loading .header__top__inner {
  min-height: 68px;
  position: relative; }

.header__name {
  font-weight: 600;
  color: #fff;
  display: block;
  font-size: 24px;
  line-height: 24px; }

.header__right {
  display: flex;
  border-top: 1px solid #4B565E;
  margin-top: 1em;
  padding-top: 1em; }

.header__value {
  flex: 1 1 50%;
  text-align: center; }

.header__value strong {
  color: #fff;
  font-size: 24px;
  line-height: 24px;
  display: block; }

.header__bottom {
  background: #222830;
  padding: 12px 20px;
  color: #bf7d26; }

.header__bottom .header__left {
  display: flex; }

.header-counter {
  position: relative;
  padding-right: 75px;
  margin-left: 10px; }

.header-counter__dec, .header-counter__inc {
  -webkit-transition: 220ms;
  transition: 220ms;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 2px;
  right: 0;
  z-index: 2;
  height: 30px;
  width: 32px;
  background: #4B565E;
  border: 1px solid #5d656b;
  cursor: pointer;
  padding: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  color: #fff; }

.header-counter__dec:hover,
.header-counter__inc:hover {
  background: #5b6872; }

.header-counter__dec:disabled,
.header-counter__inc:disabled {
  background: #222830;
  border-color: #4B565E;
  color: #4B565E;
  cursor: default; }

.header-counter__dec:disabled:hover,
.header-counter__inc:disabled:hover {
  background: #282B32; }

.header-counter__dec svg, .header-counter__inc svg {
  font-size: 0.75em;
  margin: 0 auto; }

.header-counter__inc {
  border-radius: 0 50% 50% 0; }

.header-counter__dec {
  border-radius: 50% 0 0 50%;
  right: 32px; }

.header-counter__val {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  vertical-align: text-bottom;
  line-height: 28px;
  display: inline-block;
  color: #fff; }

@media screen and (min-width: 768px) {
  .header__top {
    display: flex; }
  .header__name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .header__top__inner {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    text-align: left; }
  .header__top .header__buttons {
    flex: 1 1 240px; }
  .header__top .header__sort {
    flex: 1 1 75px; }
  .header__right {
    border-top: none;
    margin-top: 0;
    padding-top: 0; }
  .header__value {
    flex: 1 1 auto;
    margin-left: 15px; }
  .header__bottom {
    flex: 100% 1;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .header__bottom .header__left .header__value {
    margin-left: 0;
    margin-right: 20px; }
  .header__bottom .header__right .header__value {
    margin-left: 20px; } }

@media screen and (min-width: 1440px) {
  .header__bottom .header__left .header__value {
    margin-right: 30px; }
  .header__bottom .header__right .header__value {
    margin-left: 30px; }
  .header__top .header__buttons {
    flex: 1 0 auto; }
  .header__top .header__buttons .btn--primary {
    flex: 1 0 150px;
    min-width: 150px; } }

.wound-chart {
  display: flex;
  flex-flow: row wrap; }

.wound-chart__x-label {
  display: none;
  text-align: center;
  flex: 1 0 100%;
  margin: 15px 0 10px; }
  @media (min-width: 768px) {
    .wound-chart__x-label {
      display: block; } }

.wound-chart__y-label {
  display: none;
  text-align: center;
  -webkit-writing-mode: vertical-lr;
      -ms-writing-mode: tb-lr;
          writing-mode: vertical-lr;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  margin: 0 10px 0 15px; }
  @media (min-width: 768px) {
    .wound-chart__y-label {
      display: block; } }

.wound-chart__table {
  position: relative;
  z-index: 1;
  overflow: hidden;
  table-layout: fixed;
  margin: 10px;
  border-radius: 0 0 10px 10px; }
  @media (min-width: 768px) {
    .wound-chart__table {
      margin: 0 0 30px; } }
  .wound-chart__table tr {
    border-bottom: 1px solid #cac8c8; }
    .wound-chart__table tr:last-child {
      border-bottom: none; }
    .wound-chart__table tr:hover {
      background-color: rgba(220, 205, 178, 0.5); }
  .wound-chart__table thead tr {
    background-color: rgba(220, 205, 178, 0.3); }
    .wound-chart__table thead tr:last-child {
      border-bottom: 1px solid #cac8c8; }
  .wound-chart__table tbody tr:nth-child(even) {
    background-color: rgba(220, 205, 178, 0.15); }
    .wound-chart__table tbody tr:nth-child(even):hover {
      background-color: rgba(220, 205, 178, 0.5); }
  .wound-chart__table th {
    font-weight: bold;
    background-color: #ede8db; }
  .wound-chart__table th, .wound-chart__table td {
    width: 35px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    position: relative;
    border-right: 1px solid #cac8c8;
    font-size: 13px; }
    .wound-chart__table th:last-child, .wound-chart__table td:last-child {
      border-right: none; }
    .wound-chart__table th:hover, .wound-chart__table td:hover {
      background-color: #dccdb2; }
      .wound-chart__table th:hover::after, .wound-chart__table td:hover::after {
        content: "";
        position: absolute;
        background-color: rgba(220, 205, 178, 0.5);
        left: 0;
        top: -5000px;
        height: 10000px;
        width: 100%;
        z-index: -1; }
    @media (min-width: 768px) {
      .wound-chart__table th, .wound-chart__table td {
        width: 45px;
        height: 40px;
        line-height: 40px;
        font-size: 16px; } }

.simple-chart__table {
  max-width: 520px;
  margin: 10px;
  border-radius: 0 0 10px 10px; }
  @media (min-width: 768px) {
    .simple-chart__table {
      margin: 30px; } }
  .simple-chart__table tr {
    border-bottom: 1px solid #cac8c8; }
  .simple-chart__table tbody tr:last-child {
    border-bottom: none; }
  .simple-chart__table tbody tr:nth-child(even) {
    background-color: rgba(220, 205, 178, 0.15); }
  .simple-chart__table th, .simple-chart__table td {
    border-right: 1px solid #cac8c8;
    padding: 8px 12px;
    position: relative;
    font-size: 13px;
    line-height: 130%; }
    .simple-chart__table th:last-child, .simple-chart__table td:last-child {
      border-right: none; }
    @media (min-width: 768px) {
      .simple-chart__table th, .simple-chart__table td {
        font-size: 16px; } }
  .simple-chart__table th {
    padding: 8px;
    text-align: center;
    font-weight: bold;
    background-color: #ede8db;
    white-space: nowrap; }

[class^="icon"],
[class*=" icon"] {
  width: 1em;
  height: 1em;
  fill: currentColor;
  display: inline-block;
  vertical-align: text-top; }

.loader {
  position: absolute;
  top: calc(50% - 60px);
  left: calc(50% - 60px);
  height: 120px;
  width: 120px;
  -webkit-animation: axe 500ms infinite linear;
          animation: axe 500ms infinite linear;
  fill: #fff; }

.loader--small {
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  height: 50px;
  width: 50px; }

.btn .loader {
  position: relative;
  top: auto;
  left: auto;
  margin: -6px 16px -6px 20px;
  float: none;
  display: block;
  height: 32px;
  width: 32px;
  fill: #aaa; }

@-webkit-keyframes axe {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes axe {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

#header-main {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  background: #10161d;
  box-shadow: inset -10px 0 15px 0 rgba(0, 0, 0, 0.8); }

#header-main.nav-open #main-nav .nav-caller .line:first-child {
  -webkit-transform: rotate(-45deg) translate3d(-4px, 1px, 0);
          transform: rotate(-45deg) translate3d(-4px, 1px, 0);
  width: 15px; }

#header-main.nav-open #main-nav .nav-caller .line:last-child {
  -webkit-transform: rotate(45deg) translate3d(-4px, -1px, 0);
          transform: rotate(45deg) translate3d(-4px, -1px, 0);
  width: 15px; }

#header-main.nav-open #main-nav .sliding-nav {
  -webkit-transform: translateX(0);
          transform: translateX(0); }

@media screen and (min-width: 980px) {
  #header-main {
    position: -webkit-sticky;
    position: sticky;
    height: 100vh;
    flex: 0 0 260px;
    max-width: 260px;
    overflow: auto; } }

@media screen and (min-width: 980px) {
  #header-main .container {
    height: 100%;
    display: flex;
    flex-flow: column; } }

#header-main .logo {
  display: none; }

#header-main .logo-mobile {
  float: left;
  width: 180px;
  padding: 0.5em 1em; }

@media screen and (min-width: 980px) {
  #header-main .logo {
    display: block;
    width: 170px;
    margin: 1em 1em 2em; }
  #header-main .logo-mobile {
    display: none; } }

@media screen and (min-width: 980px) {
  #header-main #main-nav {
    flex: auto; }
  #header-main #main-nav .sliding-nav {
    -webkit-transform: none;
            transform: none;
    position: relative;
    max-width: none;
    width: auto;
    top: auto;
    right: auto;
    background: none;
    height: 100%; } }

#header-main #main-nav .nav-caller {
  -webkit-transition: all 220ms;
  transition: all 220ms;
  text-decoration: none;
  padding: 12px 15px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  background: none;
  border: none; }

@media screen and (min-width: 980px) {
  #header-main #main-nav .nav-caller {
    display: none; } }

#header-main #main-nav .nav-caller .line {
  -webkit-transition: 220ms;
  transition: 220ms;
  display: block;
  width: 22px;
  height: 3px;
  background: #fff;
  margin: 5px 0; }

#header-main #main-nav .sliding-nav {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition: -webkit-transform 220ms;
  transition: -webkit-transform 220ms;
  transition: transform 220ms;
  transition: transform 220ms, -webkit-transform 220ms;
  overflow: auto;
  height: calc(100vh - 52px);
  width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  background: #10161d;
  display: flex;
  flex-flow: column;
  justify-content: space-between; }

#header-main #main-nav .sliding-nav > ul {
  margin: 0;
  padding: 1em 1em 4em;
  overflow: auto; }

#header-main #main-nav .sliding-nav > .btn {
  position: absolute;
  bottom: 55px;
  left: 0;
  width: 100%; }

#header-main #main-nav .sliding-nav li a {
  -webkit-transition: 220ms;
  transition: 220ms; }

#header-main #main-nav .sliding-nav li a:hover {
  -webkit-transform: translateX(5px);
          transform: translateX(5px); }

#header-main #main-nav .sliding-nav ul button,
#header-main #main-nav .sliding-nav ul a {
  color: #fff;
  display: block;
  padding: 0.4em 1em 0.4em 0;
  text-decoration: none;
  font-family: inherit;
  background: none;
  font-weight: normal;
  font-size: 18px; }

#header-main #main-nav .sliding-nav > ul > li svg {
  margin-right: 5px;
  vertical-align: middle; }

#header-main #main-nav .sliding-nav > ul > li ul {
  margin-left: 0.5em;
  display: none; }

#header-main #main-nav .sliding-nav > ul > li li {
  font-size: 1rem; }

#header-main #main-nav .sliding-nav > ul > li.active > a {
  color: #ffb000; }

#header-main #main-nav .sliding-nav > ul > li.active ul {
  display: block; }

#header-main #main-nav .sliding-nav > ul > li li svg {
  color: #425562;
  font-size: 0.9em;
  margin-top: -4px; }

#header-main #main-nav .sliding-nav > ul > .company > a {
  font-size: 1.2em;
  color: #bf7d26;
  font-weight: 600; }

#header-main #main-nav .sliding-nav > ul > li > ul > li > a:hover {
  color: #ffb000; }

#header-main #main-nav .sliding-nav > ul > li li a {
  font-size: 16px;
  padding: 0.25em; }

#header-main #main-nav .sliding-nav > ul > li li.leader,
#header-main #main-nav .sliding-nav > ul > li li.hero,
#header-main #main-nav .sliding-nav > ul > li li.sergeant {
  font-weight: 600; }

#header-main #main-nav .sliding-nav > ul > li li.leader svg {
  color: #ffb000; }

#header-main #main-nav .sliding-nav > ul > li li.sergeant svg {
  color: #cac0cc; }

#header-main #main-nav .sliding-nav > ul > li li.hero svg {
  color: #9b6a54; }

@media screen and (min-width: 600px) {
  #header-main #main-nav .sliding-nav {
    max-width: 350px; } }

@media screen and (min-width: 980px) {
  #header-main #main-nav .sliding-nav {
    -webkit-transform: none;
            transform: none;
    position: relative;
    max-width: none;
    width: auto;
    top: auto;
    right: auto;
    background: none;
    height: 100%;
    overflow: visible; }
  #header-main #main-nav .sliding-nav > ul {
    padding: 1em; }
  #header-main #main-nav .sliding-nav > .btn {
    position: relative;
    bottom: 0; } }

@media print {
  #header-main {
    display: none; } }

.new-deck {
  overflow: hidden;
  color: #fff;
  border: 3px dashed #fff;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
  max-width: 350px;
  margin: 0.5em; }

@media only screen and (min-width: 600px) {
  .new-deck {
    margin-top: -1em; } }

.new-deck__inner {
  -webkit-transition: 220ms;
  transition: 220ms;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  min-height: 494px; }

.new-deck__inner:before {
  -webkit-transition: 220ms;
  transition: 220ms;
  content: '';
  position: absolute;
  top: 15px;
  right: 15px;
  left: 15px;
  bottom: 15px;
  border: 2px solid rgba(255, 255, 255, 0.2); }

.new-deck:hover .new-deck__inner {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

.new-deck:hover .new-deck__inner:before {
  background: rgba(255, 255, 255, 0.2);
  border-color: transparent; }

.new-deck span {
  -webkit-transition: 220ms;
  transition: 220ms;
  font-size: 28px;
  display: block;
  margin-bottom: 20px;
  font-weight: 600; }

.new-deck img {
  width: 25%;
  margin-top: -45px;
  margin-bottom: 20px; }

.new-deck .icon-plus {
  -webkit-transition: 220ms;
  transition: 220ms;
  font-size: 48px;
  border: 2px solid #fff;
  padding: 0.22em;
  border-radius: 50%;
  display: block; }

.new-card {
  overflow: hidden;
  color: #fff;
  border: 3px dashed #fff;
  border-radius: 15px;
  margin-top: 28px;
  position: relative;
  cursor: pointer; }

.card--small .new-card {
  margin-top: 0; }

.new-card__inner {
  -webkit-transition: 220ms;
  transition: 220ms;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 28px);
  min-height: 592px; }

.card--small .new-card__inner {
  min-height: 200px; }

.new-card__inner:before {
  -webkit-transition: 220ms;
  transition: 220ms;
  content: '';
  position: absolute;
  top: 15px;
  right: 15px;
  left: 15px;
  bottom: 15px;
  border: 2px solid rgba(255, 255, 255, 0.2); }

.new-card:hover .new-card__inner {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

.new-card:hover .new-card__inner:before {
  background: rgba(255, 255, 255, 0.2);
  border-color: transparent; }

.card--small .new-card:hover .new-card__inner:before {
  height: 100%;
  top: 0; }

.new-card span {
  -webkit-transition: 220ms;
  transition: 220ms;
  font-size: 28px;
  display: block;
  margin-bottom: 20px;
  font-weight: 600; }

.new-card img {
  width: 15%;
  margin-top: -40px;
  margin-bottom: 20px; }

.new-card .icon-plus {
  -webkit-transition: 220ms;
  transition: 220ms;
  font-size: 48px;
  border: 2px solid #fff;
  padding: 0.22em;
  border-radius: 50%;
  display: block; }

@media print {
  .new-card {
    display: none; } }

.overlay {
  visibility: hidden;
  opacity: 0;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(18, 25, 33, 0.75);
  align-items: center;
  justify-content: center; }

.overlay--open {
  -webkit-animation: overlay-in 220ms forwards;
          animation: overlay-in 220ms forwards;
  visibility: visible; }

@-webkit-keyframes overlay-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes overlay-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.overlay--open .overlay__inner {
  -webkit-animation: overlay-inner-in 220ms forwards cubic-bezier(0.175, 0.885, 0.32, 1.2);
          animation: overlay-inner-in 220ms forwards cubic-bezier(0.175, 0.885, 0.32, 1.2); }

@-webkit-keyframes overlay-inner-in {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes overlay-inner-in {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

.overlay--small .overlay__inner {
  width: 550px; }

.overlay__inner {
  -webkit-transition: 220ms cubic-bezier(0.175, 0.885, 0.32, 1.2);
  transition: 220ms cubic-bezier(0.175, 0.885, 0.32, 1.2);
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  max-width: 100%;
  max-height: 100%;
  position: relative;
  background: #2b323a;
  padding: 1em;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.4);
  overflow: auto;
  border-radius: 15px; }

@media screen and (min-width: 600px) {
  .overlay__inner {
    padding: 1em 1.5em 1.5em;
    max-width: calc(100vw - 2em);
    max-height: calc(100vh - 2em);
    border-radius: 30px; } }

@media screen and (min-width: 1440px) {
  .overlay__inner {
    padding: 1.5em 2.5em 2.5em; } }

.overlay__close {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  font-size: 1.75em;
  padding: 0.2em;
  color: #fff;
  background: none;
  outline: none;
  border: none; }

.overlay__close svg {
  display: block; }

.overlay__content {
  overflow: hidden;
  background: #F7F4ED;
  border-radius: 15px; }

.overlay__content__split {
  margin: 0.5em 0; }

@media screen and (min-width: 600px) {
  .overlay__content__split {
    display: flex; } }

@media screen and (min-width: 600px) {
  .overlay__content__left {
    flex: 1 1 auto; } }

.overlay__content__right {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 1em; }

.overlay__content__right h3 {
  margin: 1em 0 0; }

@media screen and (min-width: 600px) {
  .overlay__content__right {
    display: block;
    flex: 1 1 40%;
    padding-top: 0;
    padding-left: 1em; }
  .overlay__content__right h3 {
    margin: 0 0 1em; } }

.overlay__content p {
  margin-top: 0; }

.overlay__table {
  margin: 0 auto; }

@media only screen and (min-width: 600px) {
  .overlay__table {
    margin: 0; } }

.overlay__table tr {
  border: 1px solid #cac8c8; }

.overlay__table tr:nth-child(even) {
  background: none; }

.overlay__table tr.total {
  background: #fff; }

.overlay__table tr.total th {
  font-size: 18px; }

.overlay__table tr.total td {
  font-size: 28px; }

.overlay__table th {
  padding: 8px 12px;
  text-align: left;
  font-size: 14px; }

.overlay__table td {
  text-align: center;
  padding: 0;
  vertical-align: middle;
  font-size: 20px;
  font-weight: bold;
  height: 40px;
  color: #222;
  min-width: 50px; }

.overlay__table td input {
  text-align: center;
  border: none;
  border-left: 1px solid #cac8c8;
  font-size: 20px;
  font-weight: bold;
  padding: 0;
  line-height: 40px;
  width: 80px; }

.overlay h1 {
  margin: 0.25em 0 0.75em;
  color: #fff;
  font-size: 26px; }

.overlay .edit-left {
  overflow: hidden;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  height: 100%;
  position: relative; }

.overlay .edit-left.submitting {
  opacity: 0.7; }

.overlay .submit-block {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%; }

.overlay .edit-left .tabs {
  flex: 1 1;
  border-radius: 0 0 15px 15px;
  overflow: hidden; }

/* Overlays =================================================== */
#edit-rule .overlay__inner,
#edit-spell .overlay__inner,
#edit-wargear .overlay__inner {
  width: 680px; }

#edit-wargear .row--modify-special-attack, #edit-wargear .row--modify-rule, #edit-warrior .row--modify-special-attack, #edit-warrior .row--modify-rule {
  margin-bottom: 1em; }
  #edit-wargear .row--modify-special-attack > .row > .field, #edit-wargear .row--modify-rule > .row > .field, #edit-warrior .row--modify-special-attack > .row > .field, #edit-warrior .row--modify-rule > .row > .field {
    align-items: flex-start; }
    #edit-wargear .row--modify-special-attack > .row > .field label, #edit-wargear .row--modify-rule > .row > .field label, #edit-warrior .row--modify-special-attack > .row > .field label, #edit-warrior .row--modify-rule > .row > .field label {
      margin-top: .5em; }
    #edit-wargear .row--modify-special-attack > .row > .field > div, #edit-wargear .row--modify-rule > .row > .field > div, #edit-warrior .row--modify-special-attack > .row > .field > div, #edit-warrior .row--modify-rule > .row > .field > div {
      flex-grow: 1; }
      #edit-wargear .row--modify-special-attack > .row > .field > div .row, #edit-wargear .row--modify-rule > .row > .field > div .row, #edit-warrior .row--modify-special-attack > .row > .field > div .row, #edit-warrior .row--modify-rule > .row > .field > div .row {
        margin-bottom: 1em; }

#edit-company .overlay__inner {
  width: 920px; }

#edit-warrior .overlay__inner {
  width: 1220px; }

#edit-company .overlay__content,
#edit-warrior .overlay__content,
#tools .overlay__content {
  background: none;
  border-radius: 0 0 15px 15px; }

#edit-company .edit-right {
  margin-top: 1em; }

#edit-warrior .edit-left .tabs--invert {
  height: auto;
  border-radius: none; }

#edit-warrior .edit-left .tabs--invert .tabs__content {
  border-radius: 0 0 15px 15px; }

#edit-warrior .edit-left .tabs--invert .table--stats {
  border-right-color: #fff; }

#edit-warrior .edit-left .tabs--invert .table--stats thead tr th:first-child {
  background: #fff; }

@media only screen and (min-width: 600px) {
  #edit-company .edit-left .tabs {
    height: 490px;
    min-height: 490px;
    max-height: 490px; }
  #edit-warrior .edit-left .tabs {
    height: 615px;
    min-height: 615px;
    max-height: 615px; }
  #edit-warrior .edit-left .tabs .tabs {
    height: auto;
    min-height: 0;
    max-height: none; }
  #edit-company .edit-right {
    display: none; } }

@media only screen and (min-width: 980px) {
  #edit-company .overlay__content,
  #edit-warrior .overlay__content {
    display: flex; }
  #edit-company .edit-left .tabs,
  #edit-warrior .edit-left .tabs {
    border-radius: 0 0 0 15px; }
  #edit-company .edit-right {
    margin-top: 0;
    display: block;
    flex: 0 0 320px;
    padding-bottom: 6px; }
  #edit-warrior .edit-left .tabs--invert .tabs__content {
    border-radius: 0 15px 15px 15px; } }

#edit-warrior .edit-left .table--stats {
  border-right: 1em solid #F7F4ED;
  width: 100%;
  margin-bottom: 2em; }

#edit-warrior .edit-left .tabs--invert .table--stats {
  border-right: none; }

#edit-warrior .edit-left .table--stats thead tr th:first-child,
#edit-warrior .edit-left .table--stats tbody tr td:first-child {
  text-align: left;
  width: 110px;
  border-right: none; }

#result-overlay .table--stats thead tr th input,
#result-overlay .table--stats tbody tr td input,
#edit-wargear .table--stats thead tr th input,
#edit-wargear .table--stats tbody tr td input,
#edit-warrior .edit-left .table--stats thead tr th input,
#edit-warrior .edit-left .table--stats tbody tr td input {
  display: inline-block;
  width: 25px;
  padding: 0;
  text-align: center; }

#edit-warrior .edit-left .table--stats thead tr th:first-child {
  background: #F7F4ED; }

#edit-warrior .edit-right {
  margin-top: 1em; }

#edit-warrior .edit-left .accordion .table--stats {
  border-right: none;
  min-width: 0;
  flex: 1 1;
  margin-bottom: 0; }

#edit-warrior .edit-left .accordion .table--stats thead tr th:first-child,
#edit-warrior .edit-left .accordion .table--stats tbody tr td:first-child {
  text-align: center;
  width: auto;
  border-right: 1px solid #cac8c8;
  background: none; }

@media only screen and (min-width: 600px) {
  #edit-warrior .edit-right {
    display: none; } }

@media only screen and (min-width: 980px) {
  #edit-warrior .edit-right {
    margin-top: 0;
    display: block;
    flex: 0 0 360px; }
  #edit-warrior .edit-left .accordion .table--stats {
    max-width: calc(100% - 110px); } }

#result-overlay .overlay__content {
  background: none;
  border-radius: 0; }

#result-overlay .tabs__content {
  border-radius: 0 0 15px 15px;
  overflow: hidden; }

#result-overlay .overlay__content .checkbox {
  flex: 1 1 100%;
  margin-right: 0; }

#wargear-overlay .tabs__content {
  border-radius: 0 0 15px 15px; }

#wargear-details table {
  min-width: 0;
  width: 100%; }

@media only screen and (min-width: 1025px) {
  .form__container * {
    scrollbar-width: 1em;
    scrollbar-color: rgba(0, 0, 0, 0.15) #E4E2DB; }
  .form__container ::-webkit-scrollbar-track {
    background: #E4E2DB; }
  .form__container ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.15);
    border: 4px solid #E4E2DB; }
  .form__container ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.25); } }

#support-us .overlay__content {
  padding: 0.5em 1em 1em; }

#support-us .support-buttons {
  text-align: center;
  margin-top: 25px; }

@media only screen and (min-width: 600px) {
  #support-us .overlay__content {
    padding: 0.5em 2em 2em; }
  #support-us .support-buttons {
    display: flex;
    align-items: center; } }

#support-us .support-buttons strong {
  margin: 5px 10px;
  display: block; }

#support-us .support-buttons .donate {
  max-width: 180px; }

#support-us .support-buttons form img {
  position: absolute; }

.table {
  margin-bottom: 1em; }

.table thead tr {
  background: rgba(220, 205, 178, 0.3); }

.table thead tr th {
  text-align: left;
  font-weight: 600;
  padding: 0.25em 0.5em; }

.table tbody tr:nth-child(even) {
  background: rgba(0, 0, 0, 0.025); }

.table tbody tr td {
  text-align: left;
  padding: 0.5em;
  color: #5b6a72; }

.table tbody tr td strong {
  font-weight: 600; }

.table tbody .total {
  border-top: 1px solid #cac8c8;
  background: none !important; }

.table tbody .total td {
  font-weight: 600;
  font-size: 1.1em; }

.table tbody .total td:first-child {
  font-size: 1em;
  font-weight: normal; }

.table--block {
  width: 100%; }

.table--fixed {
  table-layout: fixed; }

.table--stats {
  min-width: 510px; }

.table--stats thead tr th {
  text-align: center;
  border-right: 1px solid #cac8c8; }

.table--stats thead tr th:last-child {
  border-right: none; }

.table--stats tbody tr td {
  text-align: center;
  white-space: nowrap;
  border-right: 1px solid #cac8c8; }

.table--stats tbody tr td:last-child {
  border-right: none; }

.table--overview {
  width: 100%; }

.table--overview thead tr th:last-child,
.table--overview tbody tr td:last-child {
  text-align: right; }

.table--overview tbody strong {
  font-weight: bold;
  color: #000; }

.table--small {
  min-width: 320px;
  max-width: 400px; }

@media print {
  .table thead tr {
    border-bottom: 1px solid #ddd; } }

.sort {
  position: relative;
  width: 100%;
  min-width: 60px;
  flex: 1 0 60px; }
  .sort__toggle {
    text-transform: capitalize; }
  .sort__options {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 10;
    width: 200px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); }
  .sort__option {
    display: flex;
    text-transform: capitalize;
    align-items: center;
    padding: 12px 12px 12px 20px;
    color: #c2c5c7;
    font-size: 0.825em;
    background: #3b424c;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    width: 100%;
    -webkit-transition: color 220ms, background-color 220ms, border-color 220ms;
    transition: color 220ms, background-color 220ms, border-color 220ms;
    font-family: inherit; }
    .sort__option:hover, .sort__option--selected {
      background-color: #4d545f;
      color: #fff; }
    .sort__option svg {
      margin-left: auto; }

.tabs {
  display: flex;
  flex-flow: column; }

.tabs .tabs__controls {
  padding: 0;
  margin: 0;
  display: flex;
  overflow: auto; }

@media only screen and (min-width: 600px) {
  .tabs .tabs__controls {
    padding: 0 40px 0 0;
    overflow: visible; } }

.tabs .tabs__controls li {
  flex: 0 0 auto;
  list-style: none;
  margin-right: 5px; }

.tabs .tabs__controls li.active button {
  background: #F7F4ED;
  color: #2b323a;
  border-radius: 10px 10px 0 0;
  padding-bottom: 1em;
  margin-bottom: 0; }

.tabs .tabs__controls li.active button:hover {
  background: #F7F4ED; }

.tabs .tabs__controls li button {
  border-radius: 10px;
  color: #fff;
  padding: 0.5em 1em;
  margin-bottom: 5px;
  display: block;
  text-decoration: none;
  text-align: center;
  position: relative;
  background: #4B565E;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  font-family: inherit;
  border: none;
  display: block;
  outline: none; }

.tabs .tabs__controls li button svg {
  margin: 0 10px 0px -20px;
  width: 30px;
  height: 30px;
  vertical-align: middle; }

.tabs .tabs__controls li button:hover {
  background: #5b6872; }

@media only screen and (min-width: 1440px) {
  .tabs .tabs__controls li button {
    font-size: 15px;
    padding: 0.5em 1em; }
  .tabs .tabs__controls--large li button {
    font-size: 20px;
    padding: 0.5em 1em; }
  .tabs .tabs__controls li button svg {
    margin: 0 10px 0px -30px;
    width: 40px;
    height: 40px; } }

.tabs .tabs__controls--stretch {
  padding: 0; }

.tabs .tabs__controls--stretch li {
  flex: 1 1 auto; }

.tabs .tabs__controls--stretch li:last-child {
  margin-right: 0; }

.tabs .tabs__controls--stretch li button {
  width: 100%; }

.tabs .tabs__content {
  display: none;
  background: #F7F4ED;
  overflow: auto;
  flex: 1 1; }

.tabs .tabs__content--active {
  display: flex;
  flex-direction: column; }

.tabs .tabs__content--active .form__container {
  flex: 1 1;
  overflow: auto; }

.tabs--invert .tabs__controls li.active button {
  background: #fff;
  border-bottom-color: #fff; }

.tabs--invert .tabs__controls li.active button:hover {
  background: #fff; }

.tabs--invert .tabs__controls li button {
  background: #4B565E; }

.tabs--invert .tabs__content {
  background: #fff;
  padding: 1em 1em 0; }

@media screen and (min-width: 600px) {
  .tabs--invert .tabs__content {
    padding: 1.5em 1.5em 0.5em; } }

.tabs--text .tabs__controls li.active button {
  background: rgba(34, 40, 48, 0.8);
  color: #fff; }

.tabs--text .tabs__controls li.active button:hover {
  background: rgba(34, 40, 48, 0.8); }

.tabs--text .tabs__controls li button {
  background: #4B565E; }

.tabs--text .tabs__content {
  background: rgba(34, 40, 48, 0.8);
  padding: 1em;
  border-radius: 0 20px 20px 20px; }

@media screen and (min-width: 600px) {
  .tabs--text .tabs__content {
    padding: 2em; }
  .tabs--text .tabs__controls li button {
    font-size: 1.3em; } }

.toggle-accordion {
  margin-bottom: 1em; }

.toggle-accordion__header {
  -webkit-transition: 220ms;
  transition: 220ms;
  font-size: 18px;
  padding: 0.5em 0.75em 0.5em 1em;
  background: #4B565E;
  color: #fff;
  display: block; }

.toggle-accordion__header:hover {
  background: #5b6872; }

.toggle-accordion__header:hover .toggle-control .toggle-control__inner:before {
  background: #fff; }

.toggle-accordion__header .toggle-control {
  float: right;
  margin: 2px 0; }

.toggle-accordion__inner {
  display: none;
  padding: 1em;
  background: rgba(0, 0, 0, 0.05); }

.toggle-accordion--open .toggle-accordion__inner {
  display: block; }

.toggle-control input {
  position: absolute;
  left: -999em; }

.toggle-control__inner {
  display: block;
  width: 40px;
  border-radius: 20px;
  background: #2b323a;
  padding: 3px; }

.toggle-control__inner:before {
  -webkit-transition: 220ms;
  transition: 220ms;
  content: '';
  display: block;
  position: relative;
  left: 0;
  height: 16px;
  width: 16px;
  background: #9B9792;
  border-radius: 50%; }

.toggle-control:hover .toggle-control__inner:before {
  background: #fff; }

.toggle-control input:checked ~ .toggle-control__inner:before {
  background: #fff;
  left: 18px; }

.right {
  margin-left: 15px;
  float: right;
  text-align: center;
  width: 170px; }

.right strong {
  display: block;
  margin: 15px 0 10px; }

.donate {
  -webkit-transition: 150ms;
  transition: 150ms;
  display: inline-block; }

.donate input {
  border: none;
  padding: 0; }

@media screen and (min-width: 600px) {
  .right {
    margin-top: -75px;
    margin-left: 50px;
    width: 180px; }
  .donate {
    margin: 0; } }


#search-form {
  background: #F7F4ED;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 15px; }
  @media (min-width: 768px) {
    #search-form {
      display: flex; } }
  #search-form .row {
    flex: 1 1 auto; }
    #search-form .row .field {
      margin: 0; }
    #search-form .row input {
      padding: 15px;
      line-height: 23px; }
  #search-form .btn {
    width: 100%;
    flex: 0 0 200px; }

.search-container {
  background: #F7F4ED;
  padding: 20px;
  border-radius: 15px; }

.table--search {
  width: 100%;
  text-align: left; }
  .table--search tr {
    position: relative;
    overflow: hidden; }
  .table--search th {
    color: #222; }
  .table--search td, .table--search th {
    vertical-align: middle; }
    .table--search td:nth-child(3), .table--search th:nth-child(3) {
      text-align: center; }
    .table--search td:nth-child(4), .table--search th:nth-child(4) {
      text-align: right; }
  @media (max-width: 767px) {
    .table--search .btn {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0; } }

.text-page {
  color: #fff;
  padding: 1em 0; }

.text-page h1,
.text-page h2 {
  color: #de932f;
  font-weight: 600;
  margin: 0 0 0.5em;
  font-size: 36px;
  letter-spacing: -1px; }

.text-page h3 {
  margin: 0;
  font-weight: 300;
  font-size: 26px; }

.text-page strong {
  font-weight: 600; }

.text-page a {
  color: #de932f;
  text-decoration: none; }

.text-page a:hover {
  border-bottom: 1px solid; }

.text-page p {
  font-size: 18px;
  font-weight: 300;
  margin: 0.5em 0; }

.text-page ul.styled li {
  margin: 10px 0;
  font-weight: 300;
  font-size: 20px; }

.text-page ul.styled li span {
  color: #fff; }

.text-page .intro {
  margin-bottom: 2em; }

.text-page .login-form {
  margin-top: 40px; }

.text-page #login-form .form__footer {
  flex: 0 1; }

.text-page .login-form h3 {
  margin-bottom: 0.5em; }

.text-page .section .image {
  margin-bottom: 2em; }

@media screen and (min-width: 600px) {
  .text-page {
    padding: 1em 2em; }
  .text-page .intro {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4em; }
  .text-page .intro__text {
    flex: 1 1 auto;
    max-width: 920px; }
  .text-page .login-form {
    flex: 0 0 300px;
    margin-left: 20px;
    margin-top: 0;
    padding-bottom: 0; } }

@media screen and (min-width: 1200px) {
  .text-page h1,
  .text-page h2 {
    font-size: 48px;
    letter-spacing: -2px; }
  .text-page h3 {
    font-size: 32px; }
  .text-page p {
    font-size: 20px; }
  .text-page .section {
    display: flex; }
  .text-page .section .image {
    flex: 1 1 50%; }
  .text-page .section .text {
    flex: 1 1 50%;
    padding-left: 5%; }
  .login-register-forms {
    display: flex;
    justify-content: space-between; }
  .login-register-forms > div {
    flex: 1 1 40%; }
  .login-register-forms > span {
    flex: 0 1 20%;
    display: flex;
    justify-content: center;
    align-items: center; } }

/* Reset */
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
  display: block; }

audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

audio:not([controls]) {
  display: none; }

[hidden] {
  display: none; }

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

html, button, input, select, textarea {
  font-family: sans-serif;
  color: #222; }

body {
  margin: 0;
  font-size: 1em;
  line-height: 1.4; }

::-moz-selection {
  background: #6b959d;
  color: #fff;
  text-shadow: none; }

::selection {
  background: #de932f;
  color: #fff;
  text-shadow: none; }

a:hover, a:active {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

blockquote {
  margin: 1em 40px; }

dfn {
  font-style: italic; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

ins {
  background: none;
  color: #000;
  text-decoration: none; }

mark {
  background: #ff0;
  color: #000;
  font-style: italic;
  font-weight: bold; }

pre, code, kbd, samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: none; }

q:before, q:after {
  content: "";
  content: none; }

small {
  font-size: 85%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

ul, ol {
  margin: 1em 0;
  padding: 0 0 0 40px; }

dd {
  margin: 0 0 0 40px; }

nav ul, nav ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

form {
  margin: 0; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

label {
  cursor: pointer; }

legend {
  border: 0;
  *margin-left: -7px;
  padding: 0;
  white-space: normal; }

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

button, input {
  line-height: normal; }

button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
  *overflow: visible; }

button[disabled], input[disabled] {
  cursor: default; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  *width: 13px;
  *height: 13px; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

input:invalid, textarea:invalid {
  background-color: #f0dddd; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td {
  vertical-align: top; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: black;
  padding: 0.2em 0; }

/* =============================================================================
   Non-Semantic Helper Classes
   ========================================================================== */
.ir {
  display: block;
  border: 0;
  text-indent: -999em;
  overflow: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr;
  *line-height: 0; }

.ir br {
  display: none; }

.hidden {
  display: none; }

.js .jsHide {
  display: none; }

.no-js .noJsHide {
  display: none; }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.invisible {
  visibility: hidden; }

.clearfix:before, .clearfix:after, .container:before, .container:after {
  content: '.';
  display: block;
  overflow: hidden;
  visibility: hidden;
  font-size: 0;
  line-height: 0;
  width: 0;
  height: 0; }

.clearfix:after, .container:after {
  clear: both; }

.clearfix, .container {
  zoom: 1; }

.clear {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0; }

/* =============================================================================
   Print Styles
   ========================================================================== */
@media print {
  .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {
    content: ""; }
  /* Don't show links for images, or javascript/internal links */
  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  /* h5bp.com/t */
  tr, img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p, h2, h3 {
    orphans: 3;
    widows: 3; }
  h2, h3 {
    page-break-after: avoid; }
  .advert-container {
    display: none !important; } }

/* Styles =================================================== */
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  width: 100%;
  min-width: 300px;
  background: #00060e url(/static/media/bg.2d0db1df.jpg) 0 0 no-repeat fixed;
  background-size: cover;
  color: #222;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px; }

@media only screen and (min-width: 980px) {
  #root {
    display: flex; } }

@media only screen and (min-width: 1025px) {
  * {
    scrollbar-width: 1em;
    scrollbar-color: rgba(255, 255, 255, 0.5) #434850; }
  ::-webkit-scrollbar {
    width: 1em; }
  ::-webkit-scrollbar-track {
    background: #414850;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1); }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0.5em;
    border: 4px solid #414850;
    cursor: pointer; }
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.75); } }

#wrapper {
  width: 100%;
  overflow: hidden;
  margin-top: 56px;
  padding: 1em 1em 3em;
  position: relative; }

#wrapper #wrapper-inner {
  -webkit-transition: -webkit-transform 350ms;
  transition: -webkit-transform 350ms;
  transition: transform 350ms;
  transition: transform 350ms, -webkit-transform 350ms;
  -webkit-transform: none;
          transform: none; }

@media only screen and (min-width: 980px) {
  #wrapper {
    margin-top: 0; } }

@media only screen and (min-width: 1440px) {
  #wrapper {
    padding: 2em; } }

/* Typography =================================================== */
h1, h2, h3, h4, h5, h6 {
  line-height: 100%;
  font-weight: 600; }

#main > h1 {
  color: #fff;
  font-size: 24px;
  line-height: 24px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  margin-top: 0;
  margin-bottom: 40px;
  background: #2b323a;
  padding: 22px 20px; }

#main > h2 {
  color: #de9330;
  font-weight: 600;
  font-size: 1.4em; }

#main > h2 svg {
  margin: 3px 5px 0 0; }

ul {
  padding-left: 1em; }

ul li {
  color: #de932f; }

ul li span {
  color: #222; }

a {
  -webkit-transition: color 220ms, background-color 220ms;
  transition: color 220ms, background-color 220ms; }

strong {
  font-weight: 600; }

img {
  max-width: 100%;
  height: auto; }

/* Main =================================================== */
#main {
  zoom: 1;
  min-height: 80vh; }

#main:before, #main:after {
  content: '.';
  display: block;
  overflow: hidden;
  visibility: hidden;
  font-size: 0;
  line-height: 0;
  width: 0;
  height: 0; }

#main:after {
  clear: both; }

.header-tab, .card__rank, .card__rules__header {
  border-radius: 10px 10px 0 0;
  padding: 0.4em 1em;
  display: inline-block;
  color: #fff; }

.battle-wound {
  padding: 0.5em 0.5em 0.5em 0.75em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #cac8c8; }

.battle-wound:last-child {
  border-bottom: 1px solid #cac8c8;
  margin-bottom: 0.5em; }

.battle-wound__name {
  font-weight: 600;
  margin-right: 0.5em; }

.battle-wound .switch {
  background: #cac8c8; }

@media only screen and (min-width: 600px) {
  .battle-wound {
    padding: 1em 0.5em 1em 1em; }
  .battle-wound:last-child {
    margin-bottom: 1em; } }

.rosette {
  background: url(/static/media/rosette.18dab200.svg);
  background-size: contain;
  height: 160px;
  width: 160px;
  line-height: 160px;
  font-size: 60px;
  font-weight: bold;
  text-align: center;
  display: block;
  margin: 0 auto; }

.alert {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 101;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: white;
  display: flex;
  align-items: center;
  border-left: 3em solid;
  width: 90%;
  max-width: 500px; }

.alert span {
  padding: 0.5em 1em;
  flex: 1 1 auto;
  text-align: left; }

.alert--success {
  border-left-color: #de9330; }

.alert--danger {
  border-left-color: #cc0c0c; }

.alert svg {
  flex: 0 0 3em;
  padding: 1em;
  height: 3em;
  width: 3em;
  color: #fff;
  margin-left: -3em; }

.alert .icon-check {
  padding: 0.9em; }

.alert__timer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; }

.alert__timer__inner {
  height: 3px;
  width: 50%;
  background: #de9330;
  -webkit-animation: alert-timer 5s forwards linear;
          animation: alert-timer 5s forwards linear; }

@media only screen and (min-width: 980px) {
  .alert {
    left: calc(50% + 130px); } }

@-webkit-keyframes alert-timer {
  0% {
    width: 100%; }
  100% {
    width: 0%; } }

@keyframes alert-timer {
  0% {
    width: 100%; }
  100% {
    width: 0%; } }

.help-text {
  padding: 1em;
  border: 1px solid #95b192;
  border-radius: 10px;
  background: #d8e2d6;
  position: relative; }

.help-text:before {
  content: 'Tips';
  border-radius: 8px;
  background: #95b192;
  position: absolute;
  bottom: calc(100% - 1em);
  left: 12px;
  font-size: 0.8em;
  padding: 0.25em 1em;
  color: #fff;
  font-weight: 600; }

.post-battle .card-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(18, 25, 33, 0.75);
  z-index: 100; }

.print {
  display: none; }

.donate-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 6;
  width: 100%;
  background: #2a323a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap; }

.donate-banner span {
  display: none;
  color: #fff;
  padding: 0 1em;
  font-weight: 600; }

.donate-banner .donate-link,
.donate-banner .donate-form {
  -webkit-transition: 150ms;
  transition: 150ms;
  display: block;
  flex: 1 1 50%; }

.donate-banner .donate-link:hover,
.donate-banner .donate-form:hover {
  border-bottom: none !important;
  opacity: 0.9; }

.donate-banner .donate-form input {
  border: none;
  padding: 0;
  display: block; }

.donate-banner .donate-form img {
  display: none; }

.company-rules {
  page-break-before: always;
  padding: 1em 2em; }
  .company-rules .mounts table {
    table-layout: fixed; }
    .company-rules .mounts table th {
      width: 70px; }

@media screen and (min-width: 600px) {
  .donate-banner span {
    display: block;
    flex: 1 1 auto; }
  .donate-banner .donate-link,
  .donate-banner .donate-form {
    max-width: 192px; } }

@media screen and (min-width: 980px) {
  .donate-banner {
    padding-left: 260px; }
  .donate-banner span {
    text-align: right; } }

@media print {
  body {
    background: none; }
  .print {
    display: block; }
  .no-print {
    display: none; }
  #wrapper {
    margin-top: 0;
    padding: 0; }
  .donate-banner {
    display: none; } }

@page {
  margin: 0.5cm 0.25cm 0.25cm; }

.promo-container {
  display: flex;
  flex-flow: column;
  text-align: center;
  background: rgba(43, 50, 58, 0.8);
  border-radius: 15px; }

.grid .promo-container {
  padding: 10px 10px 0; }

.company-grid .promo-container {
  max-width: 350px;
  margin: 0.5em 1em; }

.promo-container > a {
  color: #de9330;
  font-size: 14px;
  text-decoration: none;
  margin: 5px 0; }

.promo-container a:hover {
  text-decoration: underline; }

#main > .promo-container {
  margin: 0 0 1em; }

.overlay .promo-container {
  margin: 0 -1em 1em;
  background: #2a323a; }

.promo {
  background: white;
  margin: auto;
  height: 250px;
  width: 300px;
  max-width: 100%;
  background: url(/static/media/logo.4571489b.svg) 50% 50% no-repeat;
  background-size: 80% 80%; }

.promo--tall {
  height: 250px;
  width: 300px; }

.promo--wide {
  height: 50px;
  width: 320px; }

.promo--wide .promo-image-mobile {
  margin: 5px 0;
  border: 5px solid #262e35; }

.promo-image-mobile {
  display: block; }

.promo-image-desktop {
  display: none; }

@media screen and (min-width: 768px) {
  .warrior-grid .promo-container,
  .profile-grid .promo-container {
    margin-top: calc(1em + 28px); }
  .company-grid .promo-container {
    margin: 2em 1em 1.5em; }
  .promo--tall {
    height: 600px;
    width: 300px; }
  .promo--wide {
    height: 90px;
    width: 728px;
    margin-top: 10px; }
  .promo-image-mobile {
    display: none; }
  .promo-image-desktop {
    display: block; } }

@media screen and (min-width: 1440px) {
  #main > .promo-container {
    margin: -1em 0 1em; } }

