.text-page {
  color: #fff;
  padding: 1em 0;
}

.text-page h1,
.text-page h2 {
  color: #de932f;
  font-weight: 600;
  margin: 0 0 0.5em;
  font-size: 36px;
  letter-spacing: -1px;
}
.text-page h3 {
  margin: 0;
  font-weight: 300;
  font-size: 26px;
}
.text-page strong {
  font-weight: 600;
}
.text-page a {
  color: #de932f;
  text-decoration: none;
}
.text-page a:hover {
  border-bottom: 1px solid;
}
.text-page p {
  font-size: 18px;
  font-weight: 300;
  margin: 0.5em 0;
}
.text-page ul.styled li {
  margin: 10px 0;
  font-weight: 300;
  font-size: 20px;
}
.text-page ul.styled li span {
  color: #fff;
}

.text-page .intro {
  margin-bottom: 2em;
}

.text-page .login-form {
  margin-top: 40px;
}

.text-page #login-form .form__footer {
  flex: 0;
}

.text-page .login-form h3 {
  margin-bottom: 0.5em;
}

.text-page .section .image {
  margin-bottom: 2em;
}

@media screen and (min-width: 600px) {  
  .text-page {
    padding: 1em 2em;
  }
  .text-page .intro {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4em;
  }
  .text-page .intro__text {
    flex: 1 1 auto;
    max-width: 920px;
  }  
  .text-page .login-form {
    flex: 0 0 300px;
    margin-left: 20px;
    margin-top: 0;
    padding-bottom: 0;
  }  
}

@media screen and (min-width: 1200px) {
  .text-page h1,
  .text-page h2 {
    font-size: 48px;
    letter-spacing: -2px;
  }
  .text-page h3 {
    font-size: 32px;
  }
  .text-page p {
    font-size: 20px;
  }
  
  .text-page .section {
    display: flex;
  }
  
  .text-page .section .image {
    flex: 1 1 50%;
  }
  
  .text-page .section .text {
    flex: 1 1 50%;
    padding-left: 5%;
  }
  
  .login-register-forms {
    display: flex;
    justify-content: space-between;
  }
  
  .login-register-forms > div {
    flex: 1 1 40%;
  }

  .login-register-forms > span {
    flex: 0 1 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}